import React from "react";
import "./FeatureSection.css";
import Feature from "./Feature";
import {Paper} from "@material-ui/core";

class TextCard extends React.Component {
    renderListItems(items) {
        return items.map(item => {
            return this.renderItem(item);
        });
    }

    renderItem(item) {
        return (
            <div key={item.key}>
                <Feature
                    key={item.key}
                    src={item.src}
                    title={item.title}
                    color={item.color}
                    text={item.text}
                    marginLeftIcon={item.marginLeftIcon}
                />
                <br />
            </div>
        );
    }

    render() {
        return (
            <div
                style={{
                    paddingBottom: this.props.paddingBotDiv,
                    paddingTop: this.props.paddingTopDiv,
                    backgroundColor: this.props.bgColor
                }}
                id={this.props.sectionId}
            >
                <Paper
                    style={{
                        maxWidth: 1024,
                        backgroundColor: "#ffffff",
                        margin: "auto",
                        paddingTop: 80,
                        paddingBottom: 80
                    }}
                >
                    <div className="container pl-md-5 pr-md-5">
                        <div className="row">
                            <div className="col-12">
                                <div
                                    style={{
                                        fontSize: 30,
                                        color: this.props.titleColor
                                    }}
                                >
                                    <h2>{this.props.title}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-start">
                            <div className="col-12 feature-text-col">
                                {this.props.titleDescription1 ? (
                                    <p style={{color: "#979797"}}>
                                        {this.props.titleDescription1}
                                        {this.props.titleDescription2
                                            ? this.props.titleDescription2
                                            : ""}
                                    </p>
                                ) : (
                                    ""
                                )}
                                {this.renderListItems(this.props.items)}
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default TextCard;

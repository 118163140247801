import React, {Component} from 'react';
import './Main.css';
import {
    getString,
    orangetick,
    feat2,
    feat3,
    transportschemaen,
    transportschemaenx2,
    transportschemaenx3,
    transportschema,
    transportschemax2,
    transportschemax3,
    bgTransport,
    truck
} from "assets";
import FirstSection from "../components/first/FirstSection";
import FeatureSection from "../components/features/FeatureSection";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import ImageCard from "../components/services/ImageCard";


class Transport extends Component {


    componentWillMount() {
        this.updateDimensions();
        document.title = getString("transport_metatitle");
        const elements = document.getElementsByTagName("link");
        const metadesc = document.getElementsByTagName("meta");

        for (let lan = 0; lan < elements.length; lan++) {
            const element = elements[lan];
            if (element.getAttribute("rel") === "canonical") {
                element.setAttribute("href", document.URL);
            }
        }

        for (let metas = 0; metas < metadesc.length; metas++) {
            const metatag = metadesc[metas];

            if (metatag.getAttribute("name") === "description" && metatag.getAttribute("content") === "metadescription") {
                metatag.setAttribute("content", `${getString("transport_metadescription")}`)
            }
        }
        window.scrollTo(0, 0)

    }

    updateDimensions() {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }


    render() {

        let marginImage = 70;

        if (this.state.width <= 720) {
            marginImage = 0;
        }

        return (
            <div>
                <FirstSection
                    title1="O.K. Transport"
                    firstDescription={getString("web_tech")}
                    bgImage={bgTransport}
                    btnLink="/transport#ok"
                    cols="col-12 col-lg-8"
                />
                <Breadcrumbs
                    sectionId="ok"
                    breadcrumbsText={getString("transport_subtitle")}
                    marginAutomat={this.state.width <= 960 ? 30 : ""}
                    src={transportschema}
                    srcs={transportschemaen}
                    srcSet={`${transportschema} 1x,${transportschemax2} 2x,${transportschemax3} 3x`}
                    srcSets={`${transportschemaen} 1x,${transportschemaenx2} 2x,${transportschemaenx3} 3x`}
                />
                <div style={{backgroundColor: "#f5f5f5", paddingTop: 58, paddingBottom: 58}}>
                    <div style={{maxWidth: 1024, margin: "auto"}}>
                        <ImageCard
                            title={getString("includes")}
                            titleColor="#18255a"
                            imgAlign="left"
                            imgSrc={truck}
                            stick={orangetick}
                            height={356}
                            items={[
                                {
                                    key: 0,
                                    text: getString("what_happened")

                                },
                                {
                                    key: 1,
                                    text: getString("what_happening")
                                },
                                {
                                    key: 2,
                                    text: getString("will_happening")
                                },
                                {
                                    key: 3,
                                    text: getString("will_happen")
                                }
                            ]}
                        />
                    </div>
                </div>
                <FeatureSection
                    titleh2={getString("brings")}
                    titleColor="#00a298"
                    colAligment="left"
                    src={feat2}
                    srcs={feat2}
                    imgMarginTop={this.state.width <= 960 ? 30 : ""}
                    paddingRightFeat={60}
                    paddingTopFeat={85}
                    paddingBottomFeat={45}
                    divMarginTop={-80}
                    divMarginBottom={-20}
                    items={
                        [
                            {
                                key: 0,
                                title: getString("integration"),
                                text: getString("integration_description"),
                                color: "#666666"
                            },
                            {
                                key: 1,
                                title: getString("visibility"),
                                text: getString("visibility_description"),
                                color: "#666666"
                            },
                            {
                                key: 2,
                                title: getString("management_control"),
                                text: getString("management_control_description1"),
                                text2: getString("management_control_description2"),
                                color: "#666666"
                            }
                        ]
                    }
                />
                <FeatureSection
                    colAligment="right"
                    src={feat3}
                    srcs={feat3}
                    paddingRightFeat={60}
                    paddingTopFeat={85}
                    divMarginTop={-200}
                    items={
                        [
                            {
                                key: 0,
                                title: getString("cost_control"),
                                text: getString("cost_control_description"),
                                color: "#666666",
                                marginLeftIcon: marginImage
                            },
                            {
                                key: 1,
                                title: getString("pattern_detection"),
                                text: getString("pattern_detection_description"),
                                color: "#666666",
                                marginLeftIcon: marginImage
                            },
                            {
                                key: 2,
                                title: getString("your_time_profitable"),
                                text: getString("your_time_profitable_description1"),
                                text2: getString("your_time_profitable_description2"),
                                color: "#666666",
                                marginLeftIcon: marginImage
                            }
                        ]
                    }
                />
            </div>
        );
    }
}

export default Transport;

import React from "react";
import "./TableSection.css";
import Cell from "./Cell";


class TableSection extends React.Component {

    renderListItems(items) {
        return items.map((item) => {
                return this.renderItem(item)
            }
        )
    }

    renderItem(item) {
        return (<Cell
            key={item.key}
            cellCol={item.cellCol}
            text={item.text}
            number={item.number}
        />)
    }


    render() {
        return (
            <div className="container external">
                <div className="container-table">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="table-subtitle">{this.props.tableSubtitle}</h2>
                        </div>
                    </div>
                    <div className="table-section-style">
                        {this.renderListItems(this.props.items)}
                    </div>
                </div>
            </div>
        );
    }

}

export default TableSection;

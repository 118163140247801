import React from "react";
import "./Benefits.css";

class Benefits extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth
        };
    }

    updateDimensions() {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        return (
            <div id={this.props.sectionId} className="benefits-container">
                <div className="container external">
                    <div
                        className="row"
                        style={
                            this.state.width >= 768
                                ? {marginLeft: 39.5}
                                : {marginLeft: 0}
                        }
                    >
                        <div className="col-12 col-md-6">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-4">
                                    <img
                                        src={this.props.src}
                                        alt={this.props.src}
                                        className="d-block mx-auto"
                                    />
                                </div>
                                <div className="col-12 col-md-8 mt-2 pr-0">
                                    <p
                                        className="benefits-text1"
                                        style={
                                            this.state.width < 576
                                                ? {textAlign: "center"}
                                                : {}
                                        }
                                    >
                                        {this.props.text1}
                                    </p>
                                    <p
                                        className="benefits-description1"
                                        style={
                                            this.state.width < 576
                                                ? {textAlign: "center"}
                                                : {}
                                        }
                                    >
                                        {this.props.description1}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-12 col-md-6"
                            style={{marginTop: -16}}
                        >
                            <p className="benefits-text2">{this.props.text2}</p>
                            <p className="benefits-description2">
                                {this.props.description2}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Benefits;

import React from "react";
import "./Feature.css";

class Feature extends React.Component {
    render() {
        return (
            <div>
                {this.props.src === "" ? (
                    ""
                ) : (
                    <img
                        className="icon"
                        src={this.props.src}
                        alt={this.props.src}
                    />
                )}
                <div
                    className="feature-text"
                    style={{marginLeft: this.props.marginLeftIcon}}
                >
                    <p>
                        <span style={{color: this.props.color, fontSize: 20}}>
                            {this.props.title}
                        </span>
                        <br />
                        {this.props.text}
                        {this.props.text2 ? (
                            <span>
                                <br /> {this.props.text2}{" "}
                            </span>
                        ) : (
                            ""
                        )}
                    </p>
                </div>
            </div>
        );
    }
}

export default Feature;

const showMap = (q) => {
    const device = navigator.userAgent;
    let url = 'http://maps.google.com?daddr=' + q;
    if (device.match(/Iphone/i) || device.match(/iPhone|iPad|iPod/i)) {
        // iOs
        url = 'http://maps.apple.com/maps?saddr=Current%20Location&daddr=' + q;
    } else if (device.match(/Android/i)) {
        // Android
        url = 'geo:0,0?q=' + q;
    } else if (device.match(/Windows Phone/i)) {
        // windows phone
        url = 'maps:' + q;
    }

    window.location.href = url;
};

export { showMap };

import React, {Component} from "react";
import Slider from "react-slick";
import "./SliderSection.css"
import {
    prevArrow,
    nextArrow,
    avanzaWhite,
    avanzaWhitex2,
    avanzaWhitex3,
    britishcouncilWhite,
    britishcouncilWhitex2,
    britishcouncilWhitex3,
    burgerWhite,
    burgerWhitex2,
    burgerWhitex3,
    cecautoWhite,
    cecautoWhitex2,
    cecautoWhitex3,
    checkpointWhite,
    checkpointWhitex2,
    checkpointWhitex3,
    cortefielWhite,
    cortefielWhitex2,
    cortefielWhitex3,
    cruzrojaWhite,
    cruzrojaWhitex2,
    cruzrojaWhitex3,
    elcorteinglesWhite,
    elcorteinglesWhitex2,
    elcorteinglesWhitex3,
    ercrosWhite,
    ercrosWhitex2,
    ercrosWhitex3,
    famosaWhite,
    famosaWhitex2,
    famosaWhitex3,
    freixenetWhite,
    freixenetWhitex2,
    freixenetWhitex3,
    mq1White,
    mq1Whitex2,
    mq1Whitex3,
    nintendoWhite,
    nintendoWhitex2,
    nintendoWhitex3,
    normonWhite,
    normonWhitex2,
    normonWhitex3,
    osborneWhite,
    osborneWhitex2,
    osborneWhitex3,
    panricoWhite,
    panricoWhitex2,
    panricoWhitex3,
    teknonWhite,
    teknonWhitex2,
    teknonWhitex3,
    telepizzaWhite,
    telepizzaWhitex2,
    telepizzaWhitex3,
    vithasWhite,
    vithasWhitex2,
    vithasWhitex3
} from "assets";

export default class PartnersSlider extends Component {
    render() {
        const settings = {
            dots: false,
            centerMode: true,
            infinite: true,
            nextArrow: <img className="image" src={nextArrow} alt="next arrow"/>,
            prevArrow: <img className="image" src={prevArrow} alt="previous arrow"/>,
            slidesToShow: 3,
            slidesToScroll: 1,
            cssEase: "linear",
            pauseOnHover: true,
            autoplay: true,
            autoplaySpeed: 2000,
            speed: 500,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };
        return (
            <div className="slider-container">
                <div style={{display: "flex", justifyContent: "center"}}>
                    <p style={{
                        width: 538,
                        height: 47,
                        fontFamily: "Raleway",
                        fontSize: 40,
                        fontWeight: "normal",
                        fontStyle: "normal",
                        fontStretch: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: "#ffffff"
                    }}>
                        {this.props.title}
                    </p>
                </div>
                <div className="slider-style">
                    <Slider {...settings}>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={avanzaWhite}
                                    srcSet={`${avanzaWhite} 1x,${avanzaWhitex2} 2x,${avanzaWhitex3} 3x`}
                                    alt="avanza"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={britishcouncilWhite}
                                    srcSet={`${britishcouncilWhite} 1x,${britishcouncilWhitex2} 2x,${britishcouncilWhitex3} 3x`}
                                    alt="british council"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={burgerWhite}
                                    srcSet={`${burgerWhite} 1x,${burgerWhitex2} 2x,${burgerWhitex3} 3x`}
                                    alt="Burger"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={cecautoWhite}
                                    srcSet={`${cecautoWhite} 1x,${cecautoWhitex2} 2x,${cecautoWhitex3} 3x`}
                                    alt="Cecauto"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={checkpointWhite}
                                    srcSet={`${checkpointWhite} 1x,${checkpointWhitex2} 2x,${checkpointWhitex3} 3x`}
                                    alt="Checkpoint"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={cortefielWhite}
                                    srcSet={`${cortefielWhite} 1x,${cortefielWhitex2} 2x,${cortefielWhitex3} 3x`}
                                    alt="Cortefiel"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={cruzrojaWhite}
                                    srcSet={`${cruzrojaWhite} 1x,${cruzrojaWhitex2} 2x,${cruzrojaWhitex3} 3x`}
                                    alt="Cruz Roja"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={elcorteinglesWhite}
                                    srcSet={`${elcorteinglesWhite} 1x,${elcorteinglesWhitex2} 2x,${elcorteinglesWhitex3} 3x`}
                                    alt="Corte Inglés"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={ercrosWhite}
                                    srcSet={`${ercrosWhite} 1x,${ercrosWhitex2} 2x,${ercrosWhitex3} 3x`}
                                    alt="Ecros"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={famosaWhite}
                                    srcSet={`${famosaWhite} 1x,${famosaWhitex2} 2x,${famosaWhitex3} 3x`}
                                    alt="Famosa"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={freixenetWhite}
                                    srcSet={`${freixenetWhite} 1x,${freixenetWhitex2} 2x,${freixenetWhitex3} 3x`}
                                    alt="Freixenet"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={mq1White}
                                    srcSet={`${mq1White} 1x,${mq1Whitex2} 2x,${mq1Whitex3} 3x`}
                                    alt="MQ1"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={nintendoWhite}
                                    srcSet={`${nintendoWhite} 1x,${nintendoWhitex2} 2x,${nintendoWhitex3} 3x`}
                                    alt="Nintendo"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={normonWhite}
                                    srcSet={`${normonWhite} 1x,${normonWhitex2} 2x,${normonWhitex3} 3x`}
                                    alt="Normon"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={osborneWhite}
                                    srcSet={`${osborneWhite} 1x,${osborneWhitex2} 2x,${osborneWhitex3} 3x`}
                                    alt="Osborne"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={panricoWhite}
                                    srcSet={`${panricoWhite} 1x,${panricoWhitex2} 2x,${panricoWhitex3} 3x`}
                                    alt="Panrico"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={teknonWhite}
                                    srcSet={`${teknonWhite} 1x,${teknonWhitex2} 2x,${teknonWhitex3} 3x`}
                                    alt="Teknon"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={telepizzaWhite}
                                    srcSet={`${telepizzaWhite} 1x,${telepizzaWhitex2} 2x,${telepizzaWhitex3} 3x`}
                                    alt="Telepizza"/>
                            </div>
                        </div>
                        <div>
                            <div className="partner-style">
                                <img
                                    src={vithasWhite}
                                    srcSet={`${vithasWhite} 1x,${vithasWhitex2} 2x,${vithasWhitex3} 3x`}
                                    alt="Vithas"/>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        );
    }
}
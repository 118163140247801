import React from "react";
import RoundedDisplayer from "./RoundedDisplayer";
import {
    gustavo,
    gustavox2,
    gustavox3,
    arturo,
    arturox2,
    arturox3,
    eduardo,
    eduardox2,
    eduardox3,
    jordi,
    jordix2,
    jordix3,
    magdalena,
    magdalenax2,
    magdalenax3
} from "assets";
import "./GallerySection.css";

class GallerySection extends React.Component {
    render() {
        return (
            <div>
                <div className="container" id={this.props.sectionId}>
                    <div className="gallery-style">
                        <div className="external row">
                            <div className="col-12 gallery-title-style">
                                <h2 className="title-h2-gallery">{this.props.titleh2}</h2>
                                <p className="description">{this.props.text0}<br/><br/> {this.props.text1}<br/>
                                    <br/>{this.props.text2}<br/><br/> {this.props.text3}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center" style={{minHeight:120}}>
                                <h2 className="title-h2-gallery">{this.props.titleh22}</h2>
                            </div>
                        </div>
                        <div className="row gallery-row-style">
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://www.linkedin.com/in/gustavo-ranera-2909293/">
                                    <RoundedDisplayer
                                        src={gustavo}
                                        srcSetRes={`${gustavo} 1x,${gustavox2} 2x,${gustavox3} 3x`}
                                        title="Gustavo Ranera"
                                        text="Director General"
                                        text2="Socio Fundador"
                                    />
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://www.linkedin.com/in/artur-gimenez-a507125/">
                                    <RoundedDisplayer
                                        src={arturo}
                                        srcSetRes={`${arturo} 1x,${arturox2} 2x,${arturox3} 3x`}
                                        title="Arturo Giménez"
                                        text="Socio Fundador"
                                    />
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://www.linkedin.com/in/eduardo-mira-0a216722/">
                                    <RoundedDisplayer
                                        src={eduardo}
                                        srcSetRes={`${eduardo} 1x,${eduardox2} 2x,${eduardox3} 3x`}
                                        title="Eduardo Mira"
                                        text="Director Grandes Cuentas"
                                    />
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://www.linkedin.com/in/jordi-ferran-71aa4062/">
                                    <RoundedDisplayer
                                        src={jordi}
                                        srcSetRes={`${jordi} 1x,${jordix2} 2x,${jordix3} 3x`}
                                        title="Jordi Ferran"
                                        text="Director Comercial"
                                    />
                                </a>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <a href="https://www.linkedin.com/in/magdalena-ortega-4830a37/">
                                    <RoundedDisplayer
                                        src={magdalena}
                                        srcSetRes={`${magdalena} 1x,${magdalenax2} 2x,${magdalenax3} 3x`}
                                        title="Magdalena Ortega"
                                        text="Dirección Delegación Chile"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default GallerySection;

import React from "react";
import "./First.css";
import { buttons} from "assets";
import { HashLink as Link } from 'react-router-hash-link';


class FirstSection extends React.Component {
    render() {
        return (
            <div className="title-style" style={{background: `url(${this.props.bgImage}) repeat-x center center`}}>
                <div className="container external">
                    <div className="row">
                        <div className={this.props.cols} >
                            <div className="title-h1-style">
                                <h1 className="title-contact-title-style">{this.props.title1}<br/>{this.props.title2} </h1>
                            </div>
                                <div className="first-description-style">
                                    <p>{this.props.firstDescription}</p>
                                </div>
                        </div>

                    </div>
                </div>
                    <span className="btn-next">
                        <Link smooth to={this.props.btnLink}><img src={buttons} alt="next button"/></Link>
                    </span>
            </div>
        );
    }
}

export default FirstSection;

import React, {Component} from "react";
import {getString} from "assets";
import {showMap} from "./showMap.js";

class ContactHome extends Component {
    static checkPolicy() {
        if (!document.getElementById("agree").checked) {
            alert(getString("indicate_terms"));
            return false;
        }
        return true;
    }

    render() {
        return (
            <div style={{paddingTop: 48, paddingBottom: 43}}>
                <div className="container external">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="form-container">
                                <p className="form-text-style">
                                    {getString("contact_to")}
                                </p>
                                <form
                                    method="POST"
                                    action="https://us-central1-quo-services.cloudfunctions.net/optima_email"
                                    className="who-style"
                                    onSubmit={ContactHome.checkPolicy}
                                >
                                    <input
                                        hidden={true}
                                        type="text"
                                        name="redirect[success]"
                                        defaultValue={document.URL}
                                    />
                                    <input
                                        hidden={true}
                                        type="text"
                                        name="redirect[error]"
                                        defaultValue={document.URL}
                                    />
                                    <input
                                        required
                                        type="text"
                                        name="email[name]"
                                        placeholder={getString("your_name")}
                                    />
                                    <input
                                        required
                                        type="text"
                                        name="email[company]"
                                        placeholder={getString("your_company")}
                                    />
                                    <input
                                        required
                                        type="email"
                                        name="email[from]"
                                        placeholder={getString("your_email")}
                                    />
                                    <textarea
                                        required
                                        rows="6"
                                        name="email[message]"
                                        placeholder={getString("your_message")}
                                    />
                                    <input
                                        type="checkbox"
                                        name="checkbox"
                                        value="check"
                                        id="agree"
                                    />
                                    <a
                                        href="/tos/privacy-policy.html"
                                        style={{color: "rgba(68, 78, 121, 1)"}}
                                    >
                                        {" "}
                                        {getString("i_have_read_policy")}
                                    </a>
                                    <br />
                                    <input
                                        className="btn-send"
                                        type="submit"
                                        defaultValue={getString("send")}
                                    />
                                </form>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 my-auto">
                            <p
                                style={{
                                    fontFamily: "Raleway",
                                    fontSize: 38,
                                    color: "#18255a"
                                }}
                            >
                                {getString("know_more")}
                            </p>
                            <p
                                style={{
                                    marginTop: 39,
                                    fontFamily: "Raleway",
                                    fontSize: 18,
                                    color: "#979797"
                                }}
                            >
                                {getString("know_more_text1")}
                            </p>
                            <a
                                style={{
                                    fontFamily: "Lato",
                                    fontSize: 26,
                                    color: "#ee7810"
                                }}
                                href="tel:+34 917990385"
                            >
                                +34 917990385
                            </a>
                            <div>
                                <div className="row">
                                    <div className="col-12">
                                        <p
                                            style={{
                                                marginTop: 39,
                                                fontFamily: "Raleway",
                                                fontSize: 18,
                                                color: "#979797"
                                            }}
                                        >
                                            {getString("know_more_text2")}
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4 pb-4 pb-0">
                                        <p
                                            style={{
                                                fontFamily: "Lato",
                                                fontSize: 18,
                                                color: "#ee7810"
                                            }}
                                        >
                                            Madrid
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: "Lato",
                                                fontSize: 14,
                                                color: "#979797",
                                                marginBottom: 0,
                                                cursor: "pointer"
                                            }}
                                            onClick={() =>
                                                showMap(
                                                    "pinar+de+somosaguas+89+pozuelo"
                                                )
                                            }
                                        >
                                            Pinar de Somosaguas, 89 Bajo Local 11
                                            28223 Pozuelo de Alarcón
                                        </p>
                                        <a
                                            style={{
                                                marginTop: 0,
                                                fontFamily: "Lato",
                                                fontSize: 14,
                                                color: "#979797"
                                            }}
                                            href="tel:+34 917 990 385"
                                        >
                                            Tel. +34 917 990 385
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4 pb-4 pb-0">
                                        <p
                                            style={{
                                                fontFamily: "Lato",
                                                fontSize: 18,
                                                color: "#ee7810"
                                            }}
                                        >
                                            Barcelona
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: "Lato",
                                                fontSize: 14,
                                                color: "#979797",
                                                marginBottom: 0,
                                                cursor: "pointer"
                                            }}
                                            onClick={() =>
                                                showMap(
                                                    "Av.+Caritat,+1,+08338+Premià+de+Dalt,+Barcelona"
                                                )
                                            }
                                        >
                                            Av. Caritat 1, Esc B 1º 2º 08338,
                                            Premia De Dalt
                                        </p>
                                        <a
                                            style={{
                                                marginTop: 0,
                                                fontFamily: "Lato",
                                                fontSize: 14,
                                                color: "#979797"
                                            }}
                                            href="tel:+34 932 414 029"
                                        >
                                            Tel. +34 932 414 029
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4 pb-4 pb-0">
                                        <p
                                            style={{
                                                fontFamily: "Lato",
                                                fontSize: 18,
                                                color: "#ee7810"
                                            }}
                                        >
                                            Santiago de Chile
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: "Lato",
                                                fontSize: 14,
                                                color: "#979797",
                                                marginBottom: 0,
                                                cursor: "pointer"
                                            }}
                                            onClick={() =>
                                                showMap(
                                                    "Eduardo+Marquina+3937,+Of.+1103,+Vitacura"
                                                )
                                            }
                                        >
                                            Eduardo Marquina 3937 Of. 1103,
                                            Vitacura
                                        </p>
                                        <a
                                            style={{
                                                marginTop: 0,
                                                fontFamily: "Lato",
                                                fontSize: 14,
                                                color: "#979797"
                                            }}
                                            href="tel: +56 222 632 253"
                                        >
                                            Tel. +56 222 632 253
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactHome;

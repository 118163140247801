import React from "react";
import "./Cell.css";

class Cell extends React.Component {
    componentWillMount() {
        this.updateDimensions();
    }

    updateDimensions() {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {

        const style1 = {height:132, width: 160, paddingTop: 28};

        const style2 = {height:112, width: 190, paddingTop: 28};


        return (
                <div>
                    <div className="cell" style={this.state.width <= 960 ? style1 : style2}>
                        <p className="cell-text">{this.props.text}</p>
                        <p className="cell-number">{this.props.number}</p>
                    </div>
                </div>
        );
    }
}

export default Cell;
import React, {Component} from 'react';
import './Main.css';
import {getString, buttons, bgContact} from "assets";
import FirstSection from "../components/first/FirstSection";
import GallerySection from "../components/gallery/GallerySection";

class About extends Component {
    componentWillMount() {
        document.title = getString("about_metatitle");
        const elements = document.getElementsByTagName("link");
        const metadesc = document.getElementsByTagName("meta");

        for (let lan = 0; lan < elements.length; lan++) {
            const element = elements[lan];
            if (element.getAttribute("rel") === "canonical") {
                element.setAttribute("href", document.URL);
            }
        }

        for (let metas = 0; metas < metadesc.length; metas++) {
            const metatag = metadesc[metas];

            if (metatag.getAttribute("name") === "description" && metatag.getAttribute("content") === "metadescription") {
                metatag.setAttribute("content", `${getString("about_metadescription")}`)
            }
        }
        window.scrollTo(0, 0)

    }

    render() {
        return (
            <div>
                <FirstSection
                    title1={getString("more_than_a_feeling")}
                    firstDescription={getString("more_than_a_feeling1")}
                    btnImg={buttons}
                    btnLink="/about#gallery"
                    cols="col-12 col-lg-6"
                    positionTop={180}
                    bgImage={bgContact}
                />
                <GallerySection
                    sectionId="gallery"
                    titleh2={getString("know_us2")}
                    titleh22={getString("directive_team")}
                    text0={getString("meet_us_description1")}
                    text1={getString("meet_us_description2")}
                    text2={getString("meet_us_description3")}
                    text3={getString("meet_us_description4")}
                />
            </div>
        );
    }
}

export default About;

import React, {Component} from 'react';
import './Main.css';
import {getString} from "assets";
import FirstContact from "../components/first/FirstContact";
import ContactSection from "../components/contact/ContactSection";

class Contact extends Component {
    componentWillMount() {
        this.updateDimensions();
        document.title = getString("contact_metatitle");
        const elements = document.getElementsByTagName("link");
        const metadesc = document.getElementsByTagName("meta");

        for (let lan = 0; lan < elements.length; lan++) {
            const element = elements[lan];
            if (element.getAttribute("rel") === "canonical") {
                element.setAttribute("href", document.URL);
            }
        }

        for (let metas = 0; metas < metadesc.length; metas++) {
            const metatag = metadesc[metas];

            if (metatag.getAttribute("name") === "description" && metatag.getAttribute("content") === "metadescription") {
                metatag.setAttribute("content", `${getString("contact_metatitle")}`)
            }
        }
        window.scrollTo(0, 0)

    }

    updateDimensions() {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        return (
            <div>
                <FirstContact/>
                <ContactSection titleH2={getString("our_offices")}/>
            </div>
        );
    }
}

export default Contact;

import React from "react";
import {xwhite, createCookie, getCookie, getString} from "assets";
import "./CookiesSection.css";

class CookiesSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowingCookies: !getCookie("cookiesShouldBeShown")
        };
    }

    handleClose = (e) => {
        e.preventDefault();
        this.setState({isShowingCookies: false});
        createCookie("cookiesShouldBeShown", false);
    };


    renderCookiesHtml() {
        return (<div className="cookies-container">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                            <span className="cookies-text">
                                {getString("cookies_policy1")} <br/>
                                {getString("cookies_policy2")}
                            </span>
                        <div className="accept-cookies-btn" onClick={this.handleClose}>
                            Aceptar
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    render() {
        return (this.state.isShowingCookies ? this.renderCookiesHtml() : <div/>);
    }
}

export default CookiesSection;

import React, {Component} from 'react';
import './Main.css';
import {
    getString,
    avanza,
    avanzax2,
    avanzax3,
    britishcouncil,
    britishcouncilx2,
    britishcouncilx3,
    burger,
    burgerx2,
    burgerx3,
    cecauto,
    cecautox2,
    cecautox3,
    checkpoint,
    checkpointx2,
    checkpointx3,
    cortefiel,
    cortefielx2,
    cortefielx3,
    cruzroja,
    cruzrojax2,
    cruzrojax3,
    elcorteingles,
    elcorteinglesx2,
    elcorteinglesx3,
    ercros,
    ercrosx2,
    ercrosx3,
    famosa,
    famosax2,
    famosax3,
    freixenet,
    freixenetx2,
    freixenetx3,
    mq1,
    mq1x2,
    mq1x3,
    nintendo,
    nintendox2,
    nintendox3,
    normon,
    normonx2,
    normonx3,
    osborne,
    osbornex2,
    osbornex3,
    panrico,
    panricox2,
    panricox3,
    quiron,
    quironx2,
    quironx3,
    teknon,
    teknonx2,
    teknonx3,
    telepizza,
    telepizzax2,
    telepizzax3,
    vithas,
    vithasx2,
    vithasx3,
    bgDefault,
    adidas,
    audiomusica,
    deloitte,
    hotelneruda,
    metso,
    unidesarrollo,
    indra,
    cocacola
} from "assets";
import FirstSection from "../components/first/FirstSection";
import ImageTable from "../components/table/ImageTable";
import TripleImage from "../components/table/TripleImage";

class About extends Component {
    componentWillMount() {
        document.title = getString("customers_metatitle");
        const elements = document.getElementsByTagName("link");
        const metadesc = document.getElementsByTagName("meta");

        for (let lan = 0; lan < elements.length; lan++) {
            const element = elements[lan];
            if (element.getAttribute("rel") === "canonical") {
                element.setAttribute("href", document.URL);
            }
        }

        for (let metas = 0; metas < metadesc.length; metas++) {
            const metatag = metadesc[metas];

            if (metatag.getAttribute("name") === "description" && metatag.getAttribute("content") === "metadescription") {
                metatag.setAttribute("content", `${getString("customers_metadescription")}`)
            }
        }
        window.scrollTo(0, 0)

    }


    render() {
        return (
            <div>
                <FirstSection
                    title1={getString("these_are_our")}
                    title2={getString("our_customers").toLowerCase()}
                    firstDescription={getString("only_you")}
                    btnLink="/customers#image"
                    cols="col-12 col-lg-8"
                    bgImage={bgDefault}
                />
                <ImageTable
                    sectionId="image"
                    items={[
                        {

                            key: 0,
                            src: {elcorteingles},
                            srcSets: `${elcorteingles} 1x,${elcorteinglesx2} 2x,${elcorteinglesx3} 3x`,
                            text1: "El Corte Ingles",
                            text2: getString("shipping")
                        },
                        {
                            key: 1,
                            src: {cortefiel},
                            srcSets: `${cortefiel} 1x,${cortefielx2} 2x,${cortefielx3} 3x`,
                            text1: "Cortefiel",
                            text2: getString("marketing_messenger")
                        },
                        {
                            key: 2,
                            src: {cruzroja},
                            srcSets: `${cruzroja} 1x,${cruzrojax2} 2x,${cruzrojax3} 3x`,
                            text1: "Cruz Roja",
                            text2: getString("energy_fleets_insurance_telecos")
                        },
                        {
                            key: 3,
                            src: {burger},
                            srcSets: `${burger} 1x,${burgerx2} 2x,${burgerx3} 3x`,
                            text1: "Burger King",
                            text2: getString("waste_travel_energy")
                        },
                        {
                            key: 4,
                            src: {freixenet},
                            srcSets: `${freixenet} 1x,${freixenetx2} 2x,${freixenetx3} 3x`,
                            text1: "Freixenet",
                            text2: getString("shipping")
                        },
                        {
                            key: 5,
                            src: {quiron},
                            srcSets: `${quiron} 1x,${quironx2} 2x,${quironx3} 3x`,
                            text1: "Grupo Quiron",
                            text2: getString("energy_document_management_marketing")
                        },
                        {
                            key: 6,
                            src: {famosa},
                            srcSets: `${famosa} 1x,${famosax2} 2x,${famosax3} 3x`,
                            text1: "Famosa",
                            text2: getString("insurance_shipping_energy")
                        },
                        {
                            key: 7,
                            src: {avanza},
                            srcSets: `${avanza} 1x,${avanzax2} 2x,${avanzax3} 3x`,
                            text1: "Avanza",
                            text2: getString("energy_telecos")
                        },
                        {
                            key: 8,
                            src: {teknon},
                            srcSets: `${teknon} 1x,${teknonx2} 2x,${teknonx3} 3x`,
                            text1: "Teknon",
                            text2: getString("medical_material_energy")
                        },
                        {
                            key: 9,
                            src: {vithas},
                            srcSets: `${vithas} 1x,${vithasx2} 2x,${vithasx3} 3x`,
                            text1: "Vhitas Nisa",
                            text2: getString("energy_telecos_medical_gases")
                        },
                        {
                            key: 10,
                            src: {normon},
                            srcSets: `${normon} 1x,${normonx2} 2x,${normonx3} 3x`,
                            text1: "Laboratorios Normon",
                            text2: getString("shipping_fleets_cleaning")
                        },
                        {
                            key: 11,
                            src: {osborne},
                            srcSets: `${osborne} 1x,${osbornex2} 2x,${osbornex3} 3x`,
                            text1: "Osborne",
                            text2: getString("waste_document_management")
                        },
                        {
                            key: 12,
                            src: {panrico},
                            srcSets: `${panrico} 1x,${panricox2} 2x,${panricox3} 3x`,
                            text1: "Panrico",
                            text2: getString("waste")
                        },
                        {
                            key: 13,
                            src: {ercros},
                            srcSets: `${ercros} 1x,${ercrosx2} 2x,${ercrosx3} 3x`,
                            text1: "Ercros",
                            text2: getString("document_messenger")
                        },
                        {
                            key: 14,
                            src: {telepizza},
                            srcSets: `${telepizza} 1x,${telepizzax2} 2x,${telepizzax3} 3x`,
                            text1: "Telepizza",
                            text2: getString("energy")
                        },
                        {
                            key: 15,
                            src: {nintendo},
                            srcSets: `${nintendo} 1x,${nintendox2} 2x,${nintendox3} 3x`,
                            text1: "Nintendo",
                            text2: getString("telecos_transportation")
                        },
                        {
                            key: 16,
                            src: {cecauto},
                            srcSets: `${cecauto} 1x,${cecautox2} 2x,${cecautox3} 3x`,
                            text1: "Cecauto",
                            text2: getString("shipping")
                        },
                        {
                            key: 17,
                            src: {checkpoint},
                            srcSets: `${checkpoint} 1x,${checkpointx2} 2x,${checkpointx3} 3x`,
                            text1: "Checkpoint",
                            text2: getString("shipping_energy")
                        },
                        {
                            key: 18,
                            src: {britishcouncil},
                            srcSets: `${britishcouncil} 1x,${britishcouncilx2} 2x,${britishcouncilx3} 3x`,
                            text1: "British Council",
                            text2: getString("energy_document_management")
                        },
                        {
                            key: 19,
                            src: {mq1},
                            srcSets: `${mq1} 1x,${mq1x2} 2x,${mq1x3} 3x`,
                            text1: "Mequedo1",
                            text2: getString("shipping")
                        }
                    ]}
                />
                <div className="triple-latam-container">
                    <div className="triple-latam-styles">Optima Chile</div>
                    <TripleImage
                        items={[
                            {

                                key: 20,
                                src: {adidas},
                                srcSets: `${adidas} 1x,${adidas} 2x,${adidas} 3x`,
                                text1: "Adidas",
                                text2: getString("adidas_description")
                            },
                            {
                                key: 21,
                                src: {deloitte},
                                srcSets: `${deloitte} 1x,${deloitte} 2x,${deloitte} 3x`,
                                text1: "Deloitte",
                                text2: getString("deloitte_description")
                            },
                            {
                                key: 22,
                                src: {audiomusica},
                                srcSets: `${audiomusica} 1x,${audiomusica} 2x,${audiomusica} 3x`,
                                text1: "AudioMusica",
                                text2: getString("audio_description")
                            },
                            {
                                key: 23,
                                src: {hotelneruda},
                                srcSets: `${hotelneruda} 1x,${hotelneruda} 2x,${hotelneruda} 3x`,
                                text1: "Hotelera Neruda",
                                text2: getString("security_reprography")
                            },
                        ]}
                    />
                    <TripleImage
                        items={[
                            {
                                key: 24,
                                src: {unidesarrollo},
                                srcSets: `${unidesarrollo} 1x,${unidesarrollo} 2x,${unidesarrollo} 3x`,
                                text1: "Universidad del Desarrollo",
                                text2: getString("uni_description")
                            },
                            {
                                key: 25,
                                src: {metso},
                                srcSets: `${metso} 1x,${metso} 2x,${metso} 3x`,
                                text1: "Metso",
                                text2: getString("metso_description")
                            },
                            {
                                key: 26,
                                src: {indra},
                                srcSets: `${indra} 1x,${indra} 2x,${indra} 3x`,
                                text1: "Indra",
                                text2: getString("indra_description")
                            },
                            {
                                key: 27,
                                src: {cocacola},
                                srcSets: `${cocacola} 1x,${cocacola} 2x,${cocacola} 3x`,
                                text1: "Coca Cola",
                                text2: getString("coca_description")
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default About;

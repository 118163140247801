import React, {Component} from "react";
import "./Main.css";
import {getString, checked, bgHome} from "assets";
import FirstSection from "../components/first/FirstSection";
import Benefits from "../components/graphic/Benefits";
import ContactHome from "../components/contact/ContactHome";
import ServiceSection from "../components/services/ServiceSection";
import PartnersSlider from "../components/slider/PartnerSlider";
import VideoSection from "../components/video/VideoSection";

class Home extends Component {
    componentWillMount() {
        document.title = getString("home_metatitle");
        const elements = document.getElementsByTagName("link");
        const metadesc = document.getElementsByTagName("meta");

        for (let lan = 0; lan < elements.length; lan++) {
            const element = elements[lan];
            if (element.getAttribute("rel") === "canonical") {
                element.setAttribute("href", document.URL);
            }
        }

        for (let metas = 0; metas < metadesc.length; metas++) {
            const metatag = metadesc[metas];

            if (
                metatag.getAttribute("name") === "description" &&
                metatag.getAttribute("content") === "metadescription"
            ) {
                metatag.setAttribute(
                    "content",
                    `${getString("home_metadescription")}`
                );
            }
        }
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <FirstSection
                    title1={getString("cost_transform_title")}
                    firstDescription={getString("cost_transform_description")}
                    bgImage={bgHome}
                    btnLink="/optimize#benefits"
                    cols="col-12 col-lg-8"
                    positionTop={250}
                />
                <VideoSection/>
                <Benefits
                    sectionId="benefits"
                    src={checked}
                    text1="Success Fee"
                    text2="21%"
                    description1={getString("success_fee_description")}
                    description2={getString("average_savings")}
                />
                <ServiceSection
                    title={getString("our_services")}
                    bgClass="bg-grey"
                />
                <PartnersSlider title={getString("some_customers")}/>
                <ContactHome/>
            </div>
        );
    }
}

export default Home;

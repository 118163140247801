import React, {Component} from 'react';
import './Main.css';
import {
    getString,
    bluetick,
    orangetick,
    feat5,
    feat6,
    feat9,
    feat9x2,
    feat9x3,
    feat9en,
    feat9enx2,
    feat9enx3,
    servicestree,
    servicestreex2,
    servicestreex3,
    servicestreeen,
    servicestreeenx2,
    servicestreeenx3,
    greentick,
    bgServices
} from "assets";
import FirstSection from "../components/first/FirstSection";
import FeatureSection from "../components/features/FeatureSection";
import TextFeature from "../components/features/TextFeature";
import TextCard from "../components/features/TextCard";
import CircleSection from "../components/tree/CircleSection";
import DifferencesCard from "../components/cards/DifferencesCard";


class Services extends Component {
    componentWillMount() {
        this.updateDimensions();
        document.title = getString("services_metatitle");
        const elements = document.getElementsByTagName("link");
        const metadesc = document.getElementsByTagName("meta");

        for (let lan = 0; lan < elements.length; lan++) {
            const element = elements[lan];
            if (element.getAttribute("rel") === "canonical") {
                element.setAttribute("href", document.URL);
            }
        }

        for (let metas = 0; metas < metadesc.length; metas++) {
            const metatag = metadesc[metas];

            if (metatag.getAttribute("name") === "description" && metatag.getAttribute("content") === "metadescription") {
                metatag.setAttribute("content", `${getString("services_metadescription")}`)
            }
        }
    }

    updateDimensions() {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        return (
            <div>
                <FirstSection
                    title1={getString("in_our_projects")}
                    title2={getString("we_provide_solutions").toLowerCase()}
                    cols="col-12 col-lg-8"
                    btnLink="/services#checking"
                    positionTop={250}
                    bgImage={bgServices}
                />
                <FeatureSection
                    sectionId="checking"
                    colAligment="left"
                    titleh2={getString("checking")}
                    titleDescription1={getString("innovative_tech")}
                    titleColor="#18255a"
                    src={feat5}
                    srcs={feat5}
                    divMarginTop={-120}
                    divMarginBottom={-30}
                    paddingRightFeat={60}
                    paddingTopFeat={85}
                    paddingBottomFeat={20}
                    items={
                        [
                            {
                                key: 0,
                                src: bluetick,
                                title: getString("audit_recovery"),
                                marginLeftIcon: 50,
                                color: "#666666"
                            },
                            {
                                key: 1,
                                src: bluetick,
                                title: getString("checking_invoices"),
                                marginLeftIcon: 50,
                                color: "#666666"
                            }
                        ]
                    }
                />
                <TextCard
                    title={getString("energy_management")}
                    sectionId="energy"
                    titleColor="#00a298"
                    paddingBotDiv={this.state.width <= 960 ? 40 : 38}
                    paddingTopDiv={this.state.width <= 960 ? 40 : 120}
                    titleDescription1={getString("energy_management_formula")}
                    bgColor="#f5f5f5"
                    items={
                        [
                            {
                                key: 0,
                                src: greentick,
                                title: getString("billing_optimization"),
                                text: getString("billing_optimization_description"),
                                color: "#666666",
                                marginLeftIcon: 50
                            },
                            {
                                key: 1,
                                src: greentick,
                                title: getString("energy_management_outsourcing"),
                                text: getString("energy_management_outsourcing_description"),
                                color: "#666666",
                                marginLeftIcon: 50
                            },
                            {
                                key: 2,
                                src: greentick,
                                title: getString("online_manager"),
                                text: getString("online_manager_description"),
                                color: "#666666",
                                marginLeftIcon: 50
                            },
                            {
                                key: 3,
                                src: greentick,
                                title: getString("energy_efficiency"),
                                text: getString("energy_efficiency_description"),
                                color: "#666666",
                                marginLeftIcon: 50
                            },
                        ]
                    }
                />
                <FeatureSection
                    colAligment="left"
                    titleh2="Phone Manager"
                    sectionId="phone"
                    titleColor="#ee7810"
                    src={feat6}
                    srcs={feat6}
                    paddingRightFeat={60}
                    paddingTopFeat={85}
                    paddingBottomFeat={45}
                    imgMarginTop={40}
                    divMarginTop={-80}
                    divMarginBottom={-20}

                    items={
                        [
                            {
                                key: 0,
                                src: orangetick,
                                title: getString("tasks_of_management"),
                                marginLeftIcon: 50
                            },
                            {
                                key: 1,
                                src: orangetick,
                                title: getString("ungrateful_job"),
                                marginLeftIcon: 50
                            },
                            {
                                key: 2,
                                src: orangetick,
                                title: getString("laborious_service"),
                                marginLeftIcon: 50
                            }
                        ]
                    }
                />
                <TextFeature
                    title="Travel Manager"
                    titleColor="#18255a"
                    sectionId="travel"
                    titleDescription1={getString("company_size")}
                    paddingTopDiv={this.state.width <= 960 ? 40 : 120}
                    paddingBotDiv={this.state.width <= 960 ? 0 : 40}
                    bgColor="#f5f5f5"
                    items={
                        [
                            {
                                key: 0,
                                src: bluetick,
                                title: getString("service"),
                                marginLeftIcon: 50
                            },
                            {
                                key: 1,
                                src: bluetick,
                                title: getString("know_how"),
                                marginLeftIcon: 50
                            },
                            {
                                key: 2,
                                src: bluetick,
                                title: getString("travel_policy"),
                                marginLeftIcon: 50
                            }
                        ]
                    }
                />
                <CircleSection
                    bgClass="bg-grey"
                    src={servicestree}
                    srcSet={`${servicestree} 1x,${servicestreex2} 2x,${servicestreex3} 3x`}
                    srcs={servicestreeen}
                    srcSets={`${servicestreeen} 1x,${servicestreeenx2} 2x,${servicestreeenx3} 3x`}
                />
                <DifferencesCard
                    titleh2={getString("purchase_management")}
                    sectionId="purchase"
                    titleColor="#ee7810"
                />
                <FeatureSection
                    colAligment="left"
                    titleh2={getString("environmental_consultant")}
                    sectionId="environmental"
                    bgClass="bg-grey"
                    titleColor="#00a298"
                    srcs={feat9en}
                    srcSetRess={`${feat9en} 1x,${feat9enx2} 2x,${feat9enx3} 3x`}
                    src={feat9}
                    srcSetRes={`${feat9} 1x,${feat9x2} 2x,${feat9x3} 3x`}
                    paddingRightFeat={60}
                    paddingTopFeat={85}
                    paddingBottomFeat={45}
                    imgMarginTop={40}
                    divMarginTop={-80}
                    divMarginBottom={-20}
                    items={
                        [
                            {
                                key: 0,
                                src: greentick,
                                title: getString("best_solutions_eco"),
                                marginLeftIcon: 50
                            },
                            {
                                key: 1,
                                src: greentick,
                                title: getString("best_optimizations_eco"),
                                marginLeftIcon: 50
                            },
                            {
                                key: 2,
                                src: greentick,
                                title: getString("total_visions_eco"),
                                marginLeftIcon: 50
                            }
                        ]
                    }
                />
            </div>
        );
    }
}

export default Services;

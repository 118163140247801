import React, {Component} from "react";
import "./Main.css";
import {
    getString,
    bluetick,
    feat1,
    costtree,
    costtreex2,
    costtreex3,
    costtreeen,
    costtreeenx2,
    costtreeenx3,
    bgOptimization,
    dollar,
    growthGraph,
    blueCheck,
} from "assets";
import FirstSection from "../components/first/FirstSection";
import BenefitsOld from "../components/graphic/BenefitsOld";
import FeatureSection from "../components/features/FeatureSection";
import TableSection from "../components/table/TableSection";
import TreeSection from "../components/tree/TreeSection";

class Optimization extends Component {
    componentWillMount() {
        document.title = getString("home_metatitle");
        const elements = document.getElementsByTagName("link");
        const metadesc = document.getElementsByTagName("meta");

        for (let lan = 0; lan < elements.length; lan++) {
            const element = elements[lan];
            if (element.getAttribute("rel") === "canonical") {
                element.setAttribute("href", document.URL);
            }
        }

        for (let metas = 0; metas < metadesc.length; metas++) {
            const metatag = metadesc[metas];

            if (
                metatag.getAttribute("name") === "description" &&
                metatag.getAttribute("content") === "metadescription"
            ) {
                metatag.setAttribute(
                    "content",
                    `${getString("home_metadescription")}`
                );
            }
        }
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <FirstSection
                    title1={getString("reduce_your_costs")}
                    title2={getString("increase_your_benefits")}
                    btnLink="/optimize#benefits"
                    bgImage={bgOptimization}
                    cols="col-12 col-lg-8"
                    positionTop={250}
                />
                <BenefitsOld
                    sectionId="benefits"
                    title={getString("cost_optimization")}
                    src1={growthGraph}
                    src2={dollar}
                    src3={blueCheck}
                    text1={getString("optimization")}
                    text2={getString("benefit")}
                    text3="Success Fee"
                    description1={getString("optimization_description")}
                    description2={getString("benefits_description")}
                    description3={getString("success_fee_description")}
                />
                <FeatureSection
                    colAligment="left"
                    bgClass="bg-grey"
                    src={feat1}
                    srcs={feat1}
                    imgMarginBottom={50}
                    items={[
                        {
                            key: 0,
                            src: bluetick,
                            title: getString("best_solutions"),
                            marginLeftIcon: 50
                        },
                        {
                            key: 1,
                            src: bluetick,
                            title: getString("know_benchmark"),
                            marginLeftIcon: 50
                        },
                        {
                            key: 2,
                            src: bluetick,
                            title: getString("requires_compromise"),
                            marginLeftIcon: 50
                        },
                        {
                            key: 3,
                            src: bluetick,
                            title: getString("quality_costs"),
                            marginLeftIcon: 50
                        }
                    ]}
                />
                <TableSection
                    tableSubtitle={getString("global_saves_title")}
                    items={[
                        {
                            key: 0,
                            text: getString("transports").toUpperCase(),
                            number: "7-23%"
                        },
                        {
                            key: 1,
                            text: "TELECOMS",
                            number: "15-29%"
                        },
                        {
                            key: 2,
                            text: getString("residuals").toUpperCase(),
                            number: "12-32%"
                        },
                        {
                            key: 3,
                            text: getString("insurances").toUpperCase(),
                            number: "10-36%"
                        },
                        {
                            key: 4,
                            text: getString("energy").toUpperCase(),
                            number: "5-18%"
                        },
                        {
                            key: 5,
                            text: getString("messenger").toUpperCase(),
                            number: "18-43%"
                        },
                        {
                            key: 6,
                            text: getString("travel_expenses").toUpperCase(),
                            number: "12-22%"
                        },
                        {
                            key: 7,
                            text: getString("packaging").toUpperCase(),
                            number: "5-24%"
                        },
                        {
                            key: 8,
                            text: getString("office_supplies").toUpperCase(),
                            number: "13-39%"
                        },
                        {
                            key: 9,
                            text: getString("maintenance").toUpperCase(),
                            number: "5-26%"
                        },
                        {
                            key: 10,
                            text: getString("clothing").toUpperCase(),
                            number: "6-21%"
                        },
                        {
                            key: 11,
                            text: getString("graphic_material").toUpperCase(),
                            number: "12-25%"
                        },
                        {
                            key: 12,
                            text: "VESTUARIO EPIS",
                            number: "18-25%"
                        },
                        {
                            key: 13,
                            text: "ETTs",
                            number: "8-12%"
                        },
                        {
                            key: 14,
                            text: getString("cleaning").toUpperCase(),
                            number: "8-12%"
                        }
                    ]}
                />
                <TreeSection
                    bgClass="tree"
                    treeTitle={getString("methodology")}
                    src={costtree}
                    srcSet={`${costtree} 1x,${costtreex2} 2x,${costtreex3} 3x`}
                    srcs={costtreeen}
                    srcSets={`${costtreeen} 1x,${costtreeenx2} 2x,${costtreeenx3} 3x`}
                />
            </div>
        );
    }
}

export default Optimization;

import React from "react";
import "./VideoSection.css"

class VideoSection extends React.Component {
    render() {
        return (
            <div className="video-section-container">
                <div className="video-container">
                    <div className="video-wrapper">
                        <iframe className="responsive-iframe" title="video resumen" id="ytplayer" type="text/html"
                                src="https://www.youtube.com/embed/P7aMn74HpMo?playlist=P7aMn74HpMo&loop=1;rel=0&autoplay=1&controls=0&showinfo=0"
                                frameBorder="0"/>

                    </div>
                </div>
            </div>
        );
    }
}

export default VideoSection;
import React, {Component} from "react";
import Paper from "@material-ui/core/Paper";
import {NavHashLink as NavLink, HashLink as Link} from "react-router-hash-link";
import {plus} from "assets";

class ImageCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth
        };
    }

    updateDimensions() {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        return (
            <Paper
                style={{
                    position: "relative",
                    marginBottom: 80,
                    minHeight: this.props.height,
                    paddingTop: this.state.width >= 992 ? 85 : 40,
                    paddingLeft: this.state.width >= 992 ? 85 : 20,
                    paddingRight: this.state.width >= 992 ? 92 : 0
                }}
            >
                {this.props.hasLink ? (
                    <div
                        className="d-none d-lg-block"
                        style={{
                            width: 43,
                            height: 66,
                            borderRadius: "0px 10px 10px 0px",
                            backgroundColor: "#18255a",
                            position: "absolute",
                            right: -43,
                            marginTop: this.props.marginTopLink
                        }}
                    >
                        <NavLink to={this.props.link}>
                            <img
                                src={plus}
                                alt="plus"
                                style={{marginTop: 26, marginLeft: 14}}
                            ></img>
                        </NavLink>
                    </div>
                ) : (
                    ""
                )}

                {this.props.imgAlign === "right" ? (
                    <div className="row">
                        <div className="col-12 col-md-6">
                            {this.props.titleLink ? <Link to={this.props.titleLink}>
                                <p
                                    style={{
                                        fontFamily: "Raleway",
                                        fontSize: 30,
                                        color: this.props.titleColor
                                    }}
                                >
                                    {this.props.title}
                                </p>
                            </Link>
                            :
                                <p
                                    style={{
                                        fontFamily: "Raleway",
                                        fontSize: 30,
                                        color: this.props.titleColor
                                    }}
                                >
                                    {this.props.title}
                                </p>
                            }
                            {this.props.items.map(item => {
                                return (
                                    <div key={item.key}>
                                        <img
                                            src={this.props.stick}
                                            alt={this.props.stick}
                                            className="icon"
                                        ></img>
                                        {item.textLink ?
                                        <Link to={item.textLink}>
                                            <p
                                                style={{
                                                    fontFamily: "Lato",
                                                    fontSize: 20,
                                                    color: "#979797",
                                                    marginLeft: 50
                                                }}
                                            >
                                                {item.text}
                                            </p>
                                        </Link>
                                            :
                                            <p
                                                style={{
                                                    fontFamily: "Lato",
                                                    fontSize: 20,
                                                    color: "#979797",
                                                    marginLeft: 50
                                                }}
                                            >
                                                {item.text}
                                            </p>
                                        }
                                    </div>
                                );
                            })}
                        </div>
                        <div className="col-12 col-md-6 px-0 pt-3 pt-md-0">
                            <img
                                className="d-block m-auto"
                                src={this.props.imgSrc}
                                alt={this.props.imgSrc}
                            ></img>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-12 col-md-6 px-0 pb-3 pb-md-0">
                            <img
                                src={this.props.imgSrc}
                                alt={this.props.imgSrc}
                            ></img>
                        </div>
                        <div className="col-12 col-md-6">
                            {this.props.titleLink ? <Link to={this.props.titleLink}>
                                    <p
                                        style={{
                                            fontFamily: "Raleway",
                                            fontSize: 30,
                                            color: this.props.titleColor
                                        }}
                                    >
                                        {this.props.title}
                                    </p>
                                </Link>
                                :
                                <p
                                    style={{
                                        fontFamily: "Raleway",
                                        fontSize: 30,
                                        color: this.props.titleColor
                                    }}
                                >
                                    {this.props.title}
                                </p>
                            }
                            {this.props.items.map(item => {
                                return (
                                    <div key={item.key}>
                                        <img
                                            src={this.props.stick}
                                            alt={this.props.stick}
                                            className="icon"
                                        ></img>
                                        <p
                                            style={{
                                                fontFamily: "Lato",
                                                fontSize: 20,
                                                color: "#979797",
                                                marginLeft: 50
                                            }}
                                        >
                                            {item.text}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                {/* <div
                    className="d-block d-lg-none"
                    style={{
                        width: 43,
                        height: 30,
                        borderRadius: "0px 0px 10px 10px",
                        backgroundColor: "#18255a",
                        position: "absolute"
                        // right: 100,
                        // marginTop: 147
                    }}
                >
                    <img
                        src={plus}
                        alt="plus"
                        style={{marginTop: 4, marginLeft: 14}}
                    ></img>
                </div> */}
            </Paper>
        );
    }
}

export default ImageCard;
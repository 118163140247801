import React from "react";
import "./Footer.css";
import {changeLanguage, currentLanguage, logo, logox2, logox3} from "assets";
import { HashLink as Link } from 'react-router-hash-link';


class Footer extends React.Component {

    componentWillMount() {
        this.updateDimensions();
    }

    updateDimensions() {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        return (
            <div className="footer-container">
                <div className="container external">
                    <div className="row align-items-top">
                        <div className="col-6 col-sm-3">
                            <p className="footer-title">{this.props.title0}</p>
                            <p>
                                {
                                    this.props.foot0.map((foot0) => (
                                        <Link key={foot0.keys} className="footer-text" to={foot0.url}>{foot0.text}<br/></Link>
                                    ))
                                }
                            </p>
                        </div>
                        <div className="col-6 col-sm-3">
                            <p className="footer-title">{this.props.title1}</p>
                            <p>
                                {
                                    this.props.foot1.map((foot1) => (
                                            <Link smooth offset='80' key={foot1.keys} className="footer-text" to={foot1.url}>{foot1.text}<br/></Link>
                                    ))
                                }
                            </p>
                        </div>
                        <div className="col-6 col-sm-2">
                            <div>
                                <p className="footer-title">{this.props.title2}</p>
                                <p>
                                    {
                                        this.props.foot2.map((foot2) => (
                                        <Link key={foot2.keys} className="footer-text" to={foot2.url}>{foot2.text}<br/></Link>
                                        ))
                                    }
                                    <span style={{color: "#ffffff", fontSize: "12px"}}>
                         <Link
                             to="/es/"
                             onClick={(e) => {
                                 e.preventDefault();
                                 changeLanguage("es")
                             }}>{(currentLanguage === "es") ?
                             <b style={{color: "#ee7810", cursor: "pointer"}}>ES</b> : "ES"}</Link>
                                        &nbsp;/&nbsp;
                                        <Link
                                            to="/en/"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                changeLanguage("en")
                                            }}>{(currentLanguage === "en") ?
                                            <b style={{color: "#ee7810", cursor: "pointer"}}>EN</b> : "EN"}</Link>
                        </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 offset-sm-1">
                            <div>
                                <p className="footer-title">{this.props.title3}</p>
                                <p>
                                    {
                                        this.props.foot3.map((foot3) => (
                                        <a key={foot3.keys} className="privacy-style"
                                           href={foot3.url}>{foot3.text}<br/></a>
                                    ))
                                }
                                <span className="privacy-styles">© Copyright Optima 2018</span>
                            </p>
                            <img className="logo-footer-style" src={logo}
                                 srcSet={`${logo} 1x,${logox2} 2x ,${logox3} 3x`}
                                 alt="logo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;

import React from "react";
import "./Breadcrumbs.css";
import {currentLanguage} from "assets";


class Breadcrumbs extends React.Component {
    render() {
        return (
            <div className="bread-container" id={this.props.sectionId}>
                    <div className="bread-title">{this.props.breadcrumbsText}</div>
                    {currentLanguage === "es" ? <img src={this.props.src} srcSet={this.props.srcSet} alt="schema"
                                                     className="schema-style"/> :
                        <img src={this.props.srcs} srcSet={this.props.srcSets} alt="schema"
                             className="schema-style"/>}
                </div>
        );
    }

}

export default Breadcrumbs;

import React from "react";
import "./TreeSection.css";
import {currentLanguage} from "assets";

class TreeSection extends React.Component {
    render() {
        return (
            <div className={this.props.bgClass}>
                <div className="external">
                    {this.props.treeTitle ? <h2 className="title-tree">{this.props.treeTitle}</h2> : ""}
                    {currentLanguage === "es" ? <img src={this.props.src} srcSet={this.props.srcSet} alt="tree"
                                                     className="image-tree"/> :
                        <img src={this.props.srcs} srcSet={this.props.srcSets} alt="tree"
                             className="image-tree"/>}
                </div>
            </div>
        );
    }
}

export default TreeSection;

import React from "react";
import "./Benefits.css";

class BenefitsOld extends React.Component {
    render() {
        return (
            <div id={this.props.sectionId} className="benefits-container">
                <div className="container external">
                    <div className="row">
                        <div className="col-12">
                            <div className="title-benefits-style">
                                <h2>{this.props.title}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <div className="benefits">
                                <div className="benefits-son">
                                    <img
                                        src={this.props.src1}
                                        alt={this.props.src1}
                                    />
                                </div>
                                <div className="benefits-daughter">
                                    <p className="benefits-subtitle">{this.props.text1}</p>
                                    <p className="benefits-description">{this.props.description1}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="benefits">
                                <div className="benefits-son">
                                    <img
                                        src={this.props.src2}
                                        alt={this.props.src2}/>
                                </div>
                                <div className="benefits-daughter">
                                    <p className="benefits-subtitle">{this.props.text2}</p>
                                    <p className="benefits-description">{this.props.description2}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4">
                            <div className="benefits">
                                <div className="benefits-son">
                                    <img
                                        src={this.props.src3}
                                        alt={this.props.src3}/>
                                </div>
                                <div className="benefits-daughter">
                                    <p className="benefits-subtitle">{this.props.text3}</p>
                                    <p className="benefits-description">{this.props.description3}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BenefitsOld;
import React from "react";
import "./FeatureSection.css";
import Feature from "./Feature";
import {currentLanguage} from "assets";


class FeatureSection extends React.Component {
    renderListItems(items) {
        return items.map((item) => {
                return this.renderItem(item)
            }
        )
    }

    renderItem(item) {
        return (<Feature
            key={item.key}
            src={item.src}
            title={item.title}
            color={item.color}
            text={item.text}
            text2={item.text2}
            marginLeftIcon={item.marginLeftIcon}
        />)
    }

    render() {
        let row;
        if (this.props.colAligment === "right") {
            row = (<div className="row align-items-center" style={{
                marginLeft: this.props.divMarginLeft,
                marginBottom: this.props.divMarginBottom,
                marginRight: this.props.divMarginRight,
                marginTop: this.props.divMarginTop
            }}>
                <div className="col-11 offset-xs-1 col-sm-5 offset-sm-0">
                        {currentLanguage === "es" ? <img style={{
                                width: (this.props.width) ? this.props.width : "100%",
                                height: (this.props.height) ? this.props.height : "auto",
                                maxWidth: (this.props.maxWidth) ? this.props.maxWidth : "100%",
                                maxHeight: (this.props.maxHeight) ? this.props.maxHeight : "auto",
                                marginLeft: this.props.imgMarginLeft,
                                marginBottom: this.props.imgMarginBottom,
                                marginRight: this.props.imgMarginRight,
                                marginTop: this.props.imgMarginTop
                            }}
                                                         src={this.props.src}
                                                         srcSet={this.props.srcSetRes}
                                                         alt="feature"/> :
                            <img style={{
                                width: (this.props.width) ? this.props.width : "100%",
                                height: (this.props.height) ? this.props.height : "auto",
                                maxWidth: (this.props.maxWidth) ? this.props.maxWidth : "100%",
                                maxHeight: (this.props.maxHeight) ? this.props.maxHeight : "auto",
                                marginLeft: this.props.imgMarginLeft,
                                marginBottom: this.props.imgMarginBottom,
                                marginRight: this.props.imgMarginRight,
                                marginTop: this.props.imgMarginTop
                            }}
                                 src={this.props.srcs}
                                 srcSet={this.props.srcSetRess}
                                 alt="feature"/>}
                </div>
                <div className="col-11 col-sm-7 feature-col"
                     style={{
                         paddingLeft: this.props.paddingLeftFeat,
                         paddingRight: this.props.paddingRightFeat,
                         paddingTop: this.props.paddingTopFeat,
                         paddingBottom: this.props.paddingBottomFeat,
                     }}>
                    {this.props.titleh2 ?
                        <div style={{
                            fontSize: 30,
                            color: this.props.titleColor

                        }}>
                            <h2>{this.props.titleh2}</h2>
                        </div> : ""}
                    {this.props.titleDescription1 ?
                        <p style={{
                            fontFamily: "Lato",
                            fontSize: 20,
                            paddingBottom: 15,
                            color: "#979797",
                        }}>
                            {this.props.titleDescription1}<br/>
                            {this.props.titleDescription2 ? this.props.titleDescription2 : ""}
                        </p> : ""}
                    {this.renderListItems(this.props.items)}
                </div>
            </div>)
        } else {
            row = (<div className="row align-items-center" style={{
                marginLeft: this.props.divMarginLeft,
                marginBottom: this.props.divMarginBottom,
                marginRight: this.props.divMarginRight,
                marginTop: this.props.divMarginTop
            }}>
                <div className="col-12 order-1 col-sm-5 order-sm-2">
                        {currentLanguage === "es" ? <img style={{
                            width: (this.props.width) ? this.props.width : "100%",
                            height: (this.props.height) ? this.props.height : "auto",
                            maxWidth: this.props.maxWidth,
                            maxHeight: this.props.maxHeight,
                            marginLeft: this.props.imgMarginLeft,
                            marginBottom: this.props.imgMarginBottom,
                            marginRight: this.props.imgMarginRight,
                            marginTop: this.props.imgMarginTop,
                            textAlign: this.props.divTextAlign
                        }}
                                                         src={this.props.src}
                                                         srcSet={this.props.srcSetRes}
                                                         alt="feature"/> :
                            <img style={{
                                width: (this.props.width) ? this.props.width : "100%",
                                height: (this.props.height) ? this.props.height : "auto",
                                maxWidth: this.props.maxWidth,
                                maxHeight: this.props.maxHeight,
                                marginLeft: this.props.imgMarginLeft,
                                marginBottom: this.props.imgMarginBottom,
                                marginRight: this.props.imgMarginRight,
                                marginTop: this.props.imgMarginTop,
                                textAlign: this.props.divTextAlign
                            }}
                                 src={this.props.srcs}
                                 srcSet={this.props.srcSetRess}
                                 alt="feature"/>
                        }
                </div>
                <div className="col-12 order-2 col-sm-7 order-sm-1 feature-col"
                     style={{
                         paddingLeft: this.props.paddingLeftFeat,
                         paddingRight: this.props.paddingRightFeat,
                         paddingTop: this.props.paddingTopFeat,
                         paddingBottom: this.props.paddingBottomFeat,
                     }}>
                    <div>
                        {this.props.titleh2 ?
                            <div style={{
                                fontSize: 30,
                                color: this.props.titleColor,
                                marginBottom: 20

                            }}>
                                <h2>{this.props.titleh2}</h2>
                            </div> : ""}
                        {this.props.titleDescription1 ?
                            <p style={{
                                fontFamily: "Lato",
                                paddingBottom: 15,
                                fontSize: 20,
                                color: "#979797",
                            }}>
                                {this.props.titleDescription1}<br/>
                                {this.props.titleDescription2 ? this.props.titleDescription2 : ""}
                            </p> : ""}
                        {this.renderListItems(this.props.items)}
                    </div>
                </div>
            </div>)
        }
        return (
            <div className={this.props.bgClass} style={{paddingTop: 120, paddingBottom: 100}}>
                <div className="container external" id={this.props.sectionId}>
                    {row}
                </div>
            </div>
        );

    }
}

export default FeatureSection;

import React from "react";
import {Paper} from "@material-ui/core";
import Feature from "../features/Feature";
import "./Card.css";
import {
    analytics,
    getString
} from "assets";

class FeaturesCard extends React.Component {
    renderListItems(items) {
        return items.map((item) => {
                return this.renderItem(item)
            }
        )
    }

    renderItem(item) {
        return (<Feature
            key={item.key}
            src={item.src}
            title={item.title}
            color={item.color}
            text={item.text}
            text2={item.text2}
            marginLeftIcon={item.marginLeftIcon}
        />)
    }

    render() {
        return (
            <div>
                <Paper className="image-card-paper">
                    <div className="image-card-buying">
                        <img src={analytics} alt="feature"/>
                    </div>
                    <div>
                        <p className="features-text-buying">
                            {getString("features_text")}
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <p className="list-title-buying">
                                {getString("features_title1")}
                            </p>
                            {this.renderListItems(this.props.items)}
                        </div>
                        <div className="col-6">
                            <p className="list-title-buying">
                                {getString("features_title2")}
                            </p>
                            {this.renderListItems(this.props.items1)}
                        </div>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default FeaturesCard;

import React from "react";
import "./Cell.css";

class ImageCell extends React.Component {
    componentWillMount() {
        this.updateDimensions();
    }

    updateDimensions() {
        this.setState({width: window.innerWidth});
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        return (
            <div className="image-cell" style={{width: this.state.width <= 960 ? 150 :  190}}>
                <div className="card">
                    <div className="imgBx">
                        <img style={{width:"100%"}} src={this.props.src} srcSet={this.props.srcSets} alt={this.props.src}/>
                    </div>
                    <div className="details">
                        <p className="title-customer">{this.props.text1}</p>
                        <p className="description-customer">{this.props.text2}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageCell;
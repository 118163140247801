import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./scenes/Home";
import Services from "./scenes/Services";
import Transport from "./scenes/Transport";
import Customers from "./scenes/Customers";
import About from "./scenes/About";
import Contact from "./scenes/Contact";
import {getString} from  "assets";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import CookiesSection from "./components/cookies/CookiesSection";
import Optimization from "./scenes/Optimization";


class App extends Component {
    render() {
        return (
            <Router>
                <div>
                <Header/>
                <Switch>
                    <Route path="/:lan/contact" component={Contact}/>
                    <Route path="/contact" component={Contact}/>
                    <Route path="/:lan/about" component={About}/>
                    <Route path="/about" component={About}/>
                    <Route path="/:lan/customers" component={Customers}/>
                    <Route path="/customers" component={Customers}/>
                    <Route path="/:lan/services" component={Services}/>
                    <Route path="/services" component={Services}/>
                    <Route path="/:lan/transport" component={Transport}/>
                    <Route path="/transport" component={Transport}/>
                    <Route path="/:lan/optimization" component={Optimization}/>
                    <Route path="/optimization" component={Optimization}/>
                    <Route path="/" component={Home}/>
                </Switch>
                <Footer
                    title0={getString("information").toUpperCase()}
                    title1={getString("services").toUpperCase()}
                    title2={getString("contact").toUpperCase()}
                    title3="LEGAL"
                    lastColMarginLeft={40}
                    foot0={[
                        {text: getString("cost_optimization"), url: "/", keys: 0},
                        {text: "O.K. Transport", url: "/transport", keys: 1},
                        {text: getString("know_us"), url: "/about", keys: 2},
                        {text: getString("clients"), url: "/customers", keys: 3}
                    ]}
                    foot1={[
                        {text: getString("invoice_review"), url: "/servicesss#checking", keys: 0},
                        {text: getString("energy_management"), url: "/servicesss#energy", keys: 1},
                        {text: "Phone Manager", url: "/servicesss#phone", keys: 2},
                        {text: "Travel Manager", url: "/servicesss#travel", keys: 3},
                        {text: getString("purchase_management"), url: "/servicesss#purchase", keys: 4},
                        {text: getString("environmental_consulting"), url: "/servicesss#environmental", keys: 5}
                    ]}
                    foot2={[
                        {text: getString("where_we_are"), url: "/contact", keys: 0}
                    ]}
                    foot3={[
                        {text: getString("privacy_policy"), url: "/tos/privacy-policy.html", keys:0},
                        {text: getString("terms_of_use"), url: "/tos/terms-of-use.html", keys:1}
                    ]}
                />
                <CookiesSection/>
              </div>
            </Router>
        )
    }
}

ReactDOM.render(<App/>, document.getElementById('root'));

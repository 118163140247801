import React from "react";
import FeaturesCard from "./FeaturesCard";
import {orangetick, getString} from "assets";
import CardList from "./CardList";


class DifferencesCard extends React.Component {
    render() {
        return (
            <div id={this.props.sectionId}
                 style={{maxWidth: 974, margin: "auto", paddingTop: 115, paddingBottom: 115}}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}>
                    <div style={{
                        fontSize: 30,
                        color: this.props.titleColor,
                        paddingBottom: 30
                    }}>
                        <h2>{this.props.titleh2}</h2>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <FeaturesCard
                                items={[
                                    {
                                        key: 0,
                                        src: orangetick,
                                        title: "Full",
                                        marginLeftIcon: 50
                                    },
                                    {
                                        key: 1,
                                        src: orangetick,
                                        title: "Part",
                                        marginLeftIcon: 50
                                    },
                                    {
                                        key: 2,
                                        src: orangetick,
                                        title: "One Time",
                                        marginLeftIcon: 50
                                    },
                                ]}
                                items1={[
                                    {
                                        key: 0,
                                        src: orangetick,
                                        title: "Inplant",
                                        marginLeftIcon: 50
                                    },
                                    {
                                        key: 1,
                                        src: orangetick,
                                        title: "Outplant",
                                        marginLeftIcon: 50
                                    }
                                ]}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <CardList
                                items={[
                                    {
                                        key: 0,
                                        src: orangetick,
                                        title: getString("features1"),
                                        marginLeftIcon: 50
                                    },
                                    {
                                        key: 1,
                                        src: orangetick,
                                        title: getString("features2"),
                                        marginLeftIcon: 50
                                    },
                                    {
                                        key: 2,
                                        src: orangetick,
                                        title: getString("features3"),
                                        marginLeftIcon: 50
                                    },
                                    {
                                        key: 3,
                                        src: orangetick,
                                        title: getString("features4"),
                                        marginLeftIcon: 50
                                    },
                                    {
                                        key: 4,
                                        src: orangetick,
                                        title: getString("features5"),
                                        marginLeftIcon: 50
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default DifferencesCard;

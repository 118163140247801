import React from "react";
import "./TableSection.css";
import ImageCell from "./ImageCell";


class TripleImage extends React.Component {

    renderListItems(items) {
        return items.map((item) => {
                return this.renderItem(item)
            }
        )
    }

    renderItem(item) {
        return (<ImageCell
            key={item.key}
            src={item.src}
            srcSets={item.srcSets}
            text1={item.text1}
            text2={item.text2}
        />)
    }


    render() {
        return (
            <div className="container external">
                <div className="triple-image-table-style">
                    {this.renderListItems(this.props.items)}
                </div>
            </div>
        );
    }

}

export default TripleImage;

import React from "react";
import {Paper} from "@material-ui/core";
import Feature from "../features/Feature";
import "./Card.css";
import {
    process,
    getString
} from "assets";

class CardList extends React.Component {
    renderListItems(items) {
        return items.map((item) => {
                return this.renderItem(item)
            }
        )
    }

    renderItem(item) {
        return (<Feature
            key={item.key}
            src={item.src}
            title={item.title}
            color={item.color}
            text={item.text}
            text2={item.text2}
            marginLeftIcon={item.marginLeftIcon}
        />)
    }

    render() {
        return (
            <div>
                <Paper className="image-card-paper" style={{paddingBottom:65}}>
                    <div className="image-card-buying">
                        <img src={process} alt="feature"/>
                    </div>
                    <div>
                        <p className="features-text-buying">
                            {getString("features_title3")}
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {this.renderListItems(this.props.items)}
                        </div>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default CardList;
import React from "react";
import "./TreeSection.css";
import {currentLanguage} from "assets";

class TreeSection extends React.Component {
    render() {
        return (
            <div className={this.props.bgClass}>
                <div className="external">
                    {currentLanguage === "es" ? <img className="image-cirlce" src={this.props.src} srcSet={this.props.srcSet}
                    alt="cirlce" /> :
                        <img className="image-cirlce" src={this.props.srcs} srcSet={this.props.srcSets} alt="cirlce"/>}
                </div>
            </div>
        );
    }
}

export default TreeSection;

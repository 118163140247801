import React, {Component} from "react";
import {NavHashLink as NavLink} from "react-router-hash-link";
import {getString, logo, logox2, logox3} from "assets";
import "./Header.css";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: false
        };
    }

    toogleMenu = () => {
        this.setState({menu: !this.state.menu});
    };

    closeMenu = () => {
        this.setState({menu: false});
    };

    render() {
        const show = this.state.menu ? "show" : "";

        return (
            <div
                className="container-fluid navbar-collapse text-center"
                id="navbarResponsive"
                style={{
                    backgroundColor: "#3a4572",
                    position: "fixed",
                    zIndex: 9999
                }}
            >
                <div
                    style={{
                        maxWidth: 1024,
                        margin: "auto",
                        height: 84
                    }}
                >
                    <nav className="navbar navbar-expand-md navbar-dark">
                        <NavLink
                            className="nav-link"
                            to="/"
                            style={{paddingLeft: 4}}
                        >
                            <img
                                src={logo}
                                srcSet={`${logo} 1x,${logox2} 2x,${logox3} 3x`}
                                alt="logo"
                                onClick={() => this.closeMenu()}
                            />
                        </NavLink>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapsibleNavbar"
                            onClick={() => this.toogleMenu()}
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div
                            className={"collapse navbar-collapse " + show}
                            id="collapsibleNavbar"
                            style={{
                                backgroundColor: "#3a4572",
                                justifyContent: "flex-end",
                                textAlign: "left"
                            }}
                        >
                            <div className="navbar-nav">
                                <div className="nav-item dropdown">
                                    <div
                                        className="nav-link dropdown-items"
                                        id="navbarDropdownMenuLink"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        style={{
                                            paddingLeft: 31,
                                            color: "white"
                                        }}
                                    >
                                        {getString("services").toUpperCase()}
                                    </div>
                                    <div
                                        className="dropdown-menu"
                                        style={{
                                            backgroundColor: "#f5f5f5",
                                            position: "absolute"
                                        }}
                                        aria-labelledby="navbarDropdownMenuLink"
                                    >
                                        <NavLink
                                            smooth
                                            className="item"
                                            to="/optimization"
                                        >
                                            <p
                                                className="list-header"
                                                data-toggle="collapse"
                                                style={{fontWeight: "bold"}}
                                                onClick={() =>
                                                    this.toogleMenu()
                                                }
                                            >
                                                {getString("cost_optimization")}
                                            </p>
                                        </NavLink>
                                        <NavLink
                                            smooth
                                            className="item"
                                            to="/transport"
                                        >
                                            <p
                                                className="list-header"
                                                data-toggle="collapse"
                                                style={{fontWeight: "bold"}}
                                                onClick={() =>
                                                    this.toogleMenu()
                                                }
                                            >
                                                O.K. Transport
                                            </p>
                                        </NavLink>
                                        <div className="nav-item dropdown">
                                            <div
                                                id="navbarDropdownMenuLinks"
                                                role="button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <NavLink
                                                    smooth
                                                    className="item"
                                                    to="/services"
                                                >
                                                    <p
                                                        className="list-header"
                                                        data-toggle="collapse"
                                                        style={{
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        {getString(
                                                            "more_services"
                                                        )}
                                                    </p>
                                                </NavLink>
                                            </div>
                                            <div
                                                className="dropdown-menu"
                                                style={{
                                                    backgroundColor: "#f5f5f5",
                                                    position: "absolute"
                                                }}
                                                aria-labelledby="navbarDropdownMenuLinks"
                                            >
                                                <NavLink
                                                    smooth
                                                    offset="80"
                                                    className="item"
                                                    to="/services#checking"
                                                >
                                                    <p
                                                        className="list-header"
                                                        data-toggle="collapse"
                                                        onClick={() =>
                                                            this.toogleMenu()
                                                        }
                                                    >
                                                        {getString(
                                                            "invoice_review_title"
                                                        )}
                                                    </p>
                                                </NavLink>
                                                <NavLink
                                                    smooth
                                                    offset="80"
                                                    className="item"
                                                    to="/services#energy"
                                                >
                                                    <p
                                                        className="list-header"
                                                        onClick={() =>
                                                            this.toogleMenu()
                                                        }
                                                    >
                                                        {getString(
                                                            "energy_management_title"
                                                        )}
                                                    </p>
                                                </NavLink>
                                                <NavLink
                                                    smooth
                                                    offset="80"
                                                    className="item"
                                                    to="/services#phone"
                                                >
                                                    <p
                                                        className="list-header"
                                                        onClick={() =>
                                                            this.toogleMenu()
                                                        }
                                                    >
                                                        Phone Manager
                                                    </p>
                                                </NavLink>
                                                <NavLink
                                                    smooth
                                                    offset="80"
                                                    className="item"
                                                    to="/services#travel"
                                                >
                                                    <p
                                                        className="list-header"
                                                        onClick={() =>
                                                            this.toogleMenu()
                                                        }
                                                    >
                                                        Travel Manager
                                                    </p>
                                                </NavLink>
                                                <NavLink
                                                    smooth
                                                    offset="80"
                                                    className="item"
                                                    to="/services#purchase"
                                                >
                                                    <p
                                                        className="list-header"
                                                        onClick={() =>
                                                            this.toogleMenu()
                                                        }
                                                    >
                                                        {getString(
                                                            "purchase_management_title"
                                                        )}
                                                    </p>
                                                </NavLink>
                                                <NavLink
                                                    smooth
                                                    offset="80"
                                                    className="item"
                                                    to="/services#environmental"
                                                >
                                                    <p
                                                        className="list-header"
                                                        onClick={() =>
                                                            this.toogleMenu()
                                                        }
                                                    >
                                                        {getString(
                                                            "environmental_consulting_title"
                                                        )}
                                                    </p>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="/customers"
                                    activeStyle={{color: "#ee7810"}}
                                    onClick={() => this.toogleMenu()}
                                >
                                    <span className="items">
                                        {getString("clients").toUpperCase()}
                                    </span>
                                </NavLink>
                            </div>
                            <div className="nav-item dropdown">
                                <NavLink
                                    className="nav-link"
                                    to="/about"
                                    activeStyle={{color: "#ee7810"}}
                                    onClick={() => this.toogleMenu()}
                                >
                                    <span className="items">
                                        {getString("know_us").toUpperCase()}
                                    </span>
                                </NavLink>
                            </div>
                            <div className="nav-item">
                                <NavLink
                                    className="nav-link"
                                    to="/contact"
                                    activeStyle={{color: "#ee7810"}}
                                    onClick={() => this.toogleMenu()}
                                >
                                    <span className="items">
                                        {getString("contact").toUpperCase()}
                                    </span>
                                </NavLink>
                            </div>
                            <div className="col-xs d-block d-sm-none">
                                <div className="nav-item">
                                    <a
                                        className="nav-link"
                                        href="tel:+34917990385"
                                        activeStyle={{color: "#ee7810"}}
                                    >
                                    <span className="items">
                                        +34 917990385
                                    </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}

export default Header;

import React from "react";
import {
    barcelona,
    barcelonax2,
    barcelonax3,
    chile,
    chilex2,
    chilex3,
    getString,
    getUrlParam,
    pozuelo,
    pozuelox2,
    pozuelox3
} from "assets";
import { showMap } from "./showMap.js";
import "./ContactSection.css"


class ContactSection extends React.Component {

    showEmailSuccess() {
        const success = getUrlParam("success");
        if (success === "true") {
            window.alert(getString("success"))
        } else if (success === "false") {
            window.alert(getString("error"))
        }
    }

    render() {
        this.showEmailSuccess();
        return (
            <div className="container external">
                <div className="row">
                    <div
                        className="col-12 title-h2-style">
                        <h2>{ this.props.titleH2 }</h2>
                    </div>
                </div>
                <div
                    className="row row-contact-style">
                    <div
                        className="col-12 col-md-4 map-style">
                        <span
                            className="map-pointer"
                            onClick={ () => showMap("pinar+de+somosaguas+89+pozuelo") }>
                            <img
                                src={ pozuelo }
                                srcSet={ `${ pozuelo } 1x,${ pozuelox2 } 2x ,${ pozuelox3 } 3x` }
                                alt="pozuelo"/>
                        </span>
                        <p className="city-name">Madrid</p>
                        <p className="city-address">
                            Pinar de
                            Somosaguas<br/>
                            89 Bajo Local 11<br/>
                            28223, Pozuelo de
                            Alarcón<br/>
                            Tel. <a
                            href='tel:+34 917 990 385'>+34
                            917 990 385</a>
                        </p>
                    </div>
                    <div
                        className="col-12 col-md-4 map-style">
                        <span
                            className="map-pointer"
                            onClick={ () => showMap('Av.+Caritat,+1,+08338+Premià+de+Dalt,+Barcelona') }><img
                            src={ barcelona }
                            srcSet={ `${ barcelona } 1x,${ barcelonax2 } 2x ,${ barcelonax3 } 3x` }
                            alt="barcelona"/></span>
                        <p className="city-name">Barcelona</p>
                        <p className="city-address">Av.
                            Caritat 1, Esc B 1º 2º<br/>
                            08338, Premia De
                            Dalt<br/>
                            Tel. <a
                                href='tel:+34 932 414 029'>+34
                                932 414 029</a>
                        </p>
                    </div>
                    <div
                        className="col-12 col-md-4 map-style">
                        <span
                            className="map-pointer"
                            onClick={ () => showMap('Eduardo+Marquina+3937,+Of.+1103,+Vitacura') }>
                            <img src={ chile }
                                 srcSet={ `${ chile } 1x,${ chilex2 } 2x ,${ chilex3 } 3x` }
                                 alt="chile"/></span>
                        <p className="city-name">Santiago
                            de Chile</p>
                        <p className="city-address"> Eduardo
                            Marquina 3937<br/>
                            Of. 1103,
                            Vitacura<br/>
                            Tel. <a
                                href='tel:+56 222 632 253'>+56
                                222 632 253</a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }

}

export default ContactSection;

import React, {Component} from "react";
import ImageCard from "./ImageCard";
import {
    getString,
    bluetick,
    orangetick,
    greentick,
    growth,
    truck,
    moreServices
} from "assets";

class ServiceContainer extends Component {
    render() {
        return (
            <div className={this.props.bgClass}>
                <p
                    style={{
                        textAlign: "center",
                        fontSize: 40,
                        paddingTop: 74,
                        paddingBottom: 73,
                        color: "#18255a",
                        fontFamily: "Raleway"
                    }}
                >
                    {this.props.title}
                </p>
                <div
                    className="container external"
                    style={{paddingBottom: 120}}
                >
                    <ImageCard
                        title={getString("cost_optimization")}
                        titleLink="/optimization"
                        titleColor="#18255a"
                        imgAlign="right"
                        imgSrc={growth}
                        stick={bluetick}
                        height={356}
                        hasLink={true}
                        link="/optimization"
                        marginTopLink={147}
                        items={[
                            {
                                key: 0,
                                text: getString("cost_optimization_text1")
                            },
                            {
                                key: 1,
                                text: getString("cost_optimization_text2")
                            }
                        ]}
                    />
                    <ImageCard
                        title="O.K Transport"
                        titleColor="#ee7810"
                        titleLink="/transport"
                        imgAlign="left"
                        imgSrc={truck}
                        stick={orangetick}
                        height={356}
                        hasLink={true}
                        link="/transport"
                        marginTopLink={147}
                        items={[
                            {
                                key: 0,
                                text: getString("ok_transport_text1")
                            },
                            {
                                key: 1,
                                text: getString("ok_transport_text2")
                            }
                        ]}
                    />
                    <ImageCard
                        title={getString("more_services")}
                        titleLink="/services"
                        titleColor="#00a298"
                        imgAlign="right"
                        imgSrc={moreServices}
                        stick={greentick}
                        height={493}
                        hasLink={true}
                        link="/services"
                        marginTopLink={284}
                        items={[
                            {
                                key: 0,
                                text: getString("more_services_text1"),
                                textLink: "/services#checking"
                            },
                            {
                                key: 1,
                                text: getString("more_services_text2"),
                                textLink: "/services#energy"
                            },
                            {
                                key: 2,
                                text: getString("more_services_text3"),
                                textLink: "/services#phone"
                            },
                            {
                                key: 3,
                                text: getString("more_services_text4"),
                                textLink: "/services#travel"
                            },
                            {
                                key: 4,
                                text: getString("more_services_text5"),
                                textLink: "/services#purchase"
                            },
                            {
                                key: 5,
                                text: getString("more_services_text6"),
                                textLink: "/services#environmental"
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default ServiceContainer;

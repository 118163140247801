import React from "react";
import Avatar from "react-avatar";
import "./GallerySection.css";
import {LazyLoadComponent} from 'react-lazy-load-image-component';

class RoundedDisplayer extends React.Component {
    render() {
        return (
            <div className="rounded-container">
                <LazyLoadComponent>
                    <Avatar name={this.props.title}
                            size={170} src={this.props.src}
                            srcSet={this.props.srcSetRes}
                            alt={this.props.title} round={true}
                            style={{backgroundColor: "#c0c0c0"}}
                    />
                </LazyLoadComponent>
                <div className="rounded-displayer-text">
                    <span className="special-bold">{this.props.title}<br/>
                        <p className="special-normal">{this.props.text}
                            <br/> {this.props.text2 ? this.props.text2 : ""}
                        </p>
                    </span>
                </div>
            </div>
        );
    }
}

export default RoundedDisplayer;
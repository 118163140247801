import {getPathArray, createCookie, getCookie} from "./utils";

const strings = {
    es: {
        cost_optimization: "Optimización de costes",
        services: "Servicios",
        invoice_review_title: "Revisión de facturas",
        energy_management_title: "Gestión energética",
        purchase_management_title: "Gestión de compras",
        environmental_consulting_title: "Consultoría medioambiental",
        invoice_review: "Revisión de facturas",
        energy_management: "Gestión energética",
        purchase_management: "Gestión de compras",
        environmental_consulting: "Consultoría medioambiental",
        clients: "Clientes",
        know_us: "Conocenos",
        know_us2: "Nuestra trayectoria",
        contact: "Contacto",
        reduce_your_costs: "Reduce tus costes,",
        increase_your_benefits: "aumenta tus beneficios",
        our_services: "Nuestros servicios",
        optimization: "Optimización",
        optimization_description: "Optimización de los costes indirectos",
        benefit: "Beneficio",
        benefits_description:
            "Nos convertimos en beneficio para nuestros clientes",
        success_fee_description: "Tu beneficio es nuestro beneficio",
        best_solutions:
            "Diseñamos las mejores soluciones a medida de cada cliente",
        methodology_and_tools: "Metodología y herramientas de análisis",
        savings: "Ahorros mantenidos en el tiempo",
        adopted_solutions: "Acompañamos en la implementación",
        management_and_control:
            "Gestión y control durante el proyecto. Checking, cálculo ahorros y búsqueda de mejoras",
        market_benchmark: "Nuestra experiencia aporta el benchmark de mercado",
        requirements: "Requerimos tu compromiso, no tu tiempo",
        quality_and_price: "Calidad y precio son compatibles",
        global_saves_title: "Promedio global de ahorro: 21%",
        transports: "Transportes",
        residuals: "Residuos",
        insurances: "Seguros",
        energy: "Energía",
        messenger: "Mensajería",
        travel_expenses: "Gastos de viaje",
        packaging: "Embalajes",
        office_supplies: "Material oficina",
        maintenance: "Mantenimiento",
        clothing: "Vestuario",
        graphic_material: "Material gráfico",
        security: "Seguridad",
        cleaning: "Limpieza",
        where_we_are: "Donde estamos",
        privacy_policy: "Política de privacidad",
        terms_of_use: "Términos y Condiciones",
        information: "Información",
        web_tech: "Tecnología web para la gestión y control del coste",
        transport_subtitle:
            "Optima OK Transport transforma datos en beneficios",
        data: "Datos",
        info: "Información",
        knowledge: "Conocimiento",
        actions: "Acciones",
        benefits: "Beneficios",
        what_happened: "Sistemas de Reporting y análisis",
        what_happening: "Dashboards con representación gráfica",
        where_happening:
            "Sistemas de información geográfica, posicionada en mapa (Donde está pasando)",
        will_happening: "Cuadros de Mando",
        will_happen: "Homogenización y valoración de ofertas",
        includes: "O.K. Transport incluye:",
        integration: "Integración",
        integration_description:
            "Se integran diversas fuentes de datos presentándolos de forma agrupada, facilitando su análisis",
        visibility: "Visibilidad",
        visibility_description:
            "Tener perspectiva e información histórica para poder fijar estrategias a medio y largo plazo",
        management_control: "Control de gestión",
        management_control_description1:
            "100% Checking y validación de las facturas",
        management_control_description2: "Detección y reclamación de errores",
        brings: "O.K. Transport aporta:",
        customizable_report: "Informe personalizable",
        customizable_report_description:
            "Aparte del Reporting fundamental, se habilita la personalización de informes",
        cost_control: "Control de costes",
        cost_control_description:
            "Por mejora condiciones, detección errores, reducción costes administrativos…",
        your_time_profitable: "Rentabiliza tu tiempo",
        your_time_profitable_description1:
            "Estudios demuestran que un 30% del tiempo se dedica a elaboración de informes",
        your_time_profitable_description2:
            "Perspectiva y simplificación para la toma de decisiones",
        accomplishments: "Cumplimientos SLA’ s",
        accomplishments_description:
            "Establecer un control y seguimiento sobre los SLA’ s de servicio pactados con los proveedores",
        control_kpi: "Fijación y control de KPIs",
        control_kpi_description:
            "Deja que O.K. se preocupe de avisarte de cualquier incidencia o problema, de acuerdo a los parámetros y criterios establecidos en el setup",
        pattern_detection: "Detección de patrones",
        pattern_detection_description:
            "Permitiendo adecuar contrataciones, rutas, almacenes… a los usos reales",
        we_are_more_than_40: "Somos más de 35",
        campaign_workers: "Colaboradores",
        since_1996: "Desarrollamos proyectos desde 1996",
        meet_us: "Conócenos",
        meet_us_description1:
            "Optima ha colaborado con más 200 clientes en estos 25 años.",
        meet_us_description2:
            "Se han analizado más de 500 proyectos, en más de 20 categorías de coste.",
        meet_us_description3:
            "Más de 600 M€ de coste con unos ahorros medios del 21%.",
        meet_us_description4:
            "Dando cobertura en España, así como en Sudamérica y Europa, mediante colaboradores y delegaciones.",
        need_more: "Empieza a ahorrar",
        something_else: "ya",
        write_or_call: "Escríbenos o llámanos: ",
        contact_to: "Contactar",
        your_name: "Tu nombre",
        your_company: "Empresa",
        your_email: "Tu email",
        your_message: "Tu mensaje",
        send: "Enviar",
        our_offices: "Nuestras oficinas",
        error:
            "Hemos tenido un error tratando de enviar tu mensaje. Por favor envíanos un email a emira@optimacc.com",
        success:
            "Tu mensaje ha sido enviado con éxito. Gracias por su interés.",
        in_our_projects: "En nuestros proyectos",
        we_provide_solutions: "Aportamos soluciones innovadoras",
        checking: "Revisión de facturas - Checking",
        innovative_tech:
            "Los errores de facturación pueden representar entre el 3 % y el 5% del coste.",
        errors_location:
            "Localización de errores por incorrecta aplicación de condiciones en cualquier categoría de coste.",
        audit_recovery: "Audit Recovery (Checking facturas anteriores)",
        checking_invoices: "Checking facturas (Checking mensual)",
        these_are_our: "Estos son algunos de",
        our_customers: "Nuestros clientes",
        only_you: "Sólo faltas tú",
        energy_management_formula:
            "Nueva fórmula de gestión energética, gracias a la monitorización y control de los consumos energéticos en la nube, empieza a ahorrar desde el primer día sin inversión.",
        billing_optimization: "Optimización",
        billing_optimization_description:
            "Analizando consumos históricos, adaptamos la contratación en el mercado a los usos reales. Alcanzamos ahorros de hasta el 15% sobre el coste energético.",
        energy_management_outsourcing: "Externalización",
        energy_management_outsourcing_description:
            "Gestionamos de manera sistemática y continua el consumo energético de las instalaciones.",
        online_manager: "Gestor on-line",
        online_manager_description:
            "La monitorización y control de tu consumo a un solo click en entorno web mediante plataforma en la nube.",
        energy_efficiency: "Eficiencia Energética",
        energy_efficiency_description:
            "Disminución del consumo, mediante la búsqueda de las mejores soluciones aplicando las últimas tecnologías, adaptadas al cliente.",
        tasks_of_management:
            "Las tareas de gestión, control, resolución de incidencias… recae sobre varios departamentos.",
        ungrateful_job: "Externalización de una tarea ingrata y laboriosa.",
        laborious_service:
            "Se pueden contratar todas las telecomunicaciones o parcialmente (Fijo, Móvil, Datos, Red Inteligente).",
        contact_all:
            "Se pueden contratar todas las telecomunicaciones o parcialmente (Fijo, Móvil, Datos, Red Inteligente).",
        company_size:
            "La responsabilidad se distribuye en diversos departamentos, no teniendo la adecuada visibilidad del coste, para su adecuada gestión.",
        service:
            "Servicio: la sencillez y la eficacia son los principos en los que basamos nuestro servicio",
        know_how:
            "Conocimiento: Equipo experto y próximo al cliente, capaz de adaptarse a sus necesidades",
        travel_policy:
            "Política de Viajes: adaptamos e implementamos las soluciones óptimas a cada organización",
        outsourcing_services:
            "Externalización de servicios en áreas de especialización de Optima. Con diferentes alternativas:",
        full_time: "Full, Part / One Time",
        implant_outplant: "Inplant / Outplant",
        bpo_service:
            "Mediante el servicio de BPO (Business Process Outsourcing) damos apoyo a los equipos de compra y dirección financiera en algunos de los principales procesos de negocio. Destacamos los siguientes, si bien se podrían extender en función de las necesidades:",
        rfi_rfq: "Elaboración de RFI / RFQs",
        simulation_scenes: "Valoración y simulación de escenarios",
        preparation_negotiation: "Preparación en la negociación.",
        preparation_negotiation_description: "(Cambio proveedores, procesos…).",
        development_scorecards: "Elaboración de cuadros de mando",
        control_and_maintenance: "Servicios de control y mantenimiento",
        enviromental_management:
            "La gestión medioambiental tiene un enfoque estratégico.",
        enviromental_matters:
            "Asesoramos, tramitamos y generamos rentabilidad en materia de medio ambiente.",
        rsc_policy:
            "Enmarcado dentro de la política RSC (renovables, economía circular, medioambiente, huella de carbono…).",
        adaptation_waste:
            "Incluyendo el análisis y a la adecuación de residuos, energía, flotas… a las políticas marcadas por la RSC.",
        solutions_waste:
            "Buscamos las mejores soluciones a la gestión de residuos, convirtiendo tu empresa en eco-friendly y ayudándote en el ahorro de costes energéticos.",
        optimization_resources:
            "Optimización de los recursos y ayuda a cumplir toda la normativa legal vigente y las previstas futuras.",
        total_vision:
            "Visión total de las variables necesarias en la gestión de la RSC (consumos, emisiones CO2, huella de carbono…).",
        service_to_develop: "Servicio a desarrollar en 2 fases:",
        analysis_and_auditing: "Análisis y auditoria de la situación",
        analysis_and_auditing_description:
            "Con propuesta de cambios y nuevos procedimientos.",
        management_tool: "Herramienta gestión coste",
        management_tool_description: "Se adecua y completa lo anterior.",
        methodology: "Metodología",
        shipping: "Transporte",
        marketing_messenger: "Marketing y Mensajería",
        energy_fleets_insurance_telecos: "Energía, Flotas, Seguros y Telecos",
        waste_travel_energy: "Residuos, Viajes, Energía y Mensajería",
        energy_document_management_marketing:
            "Energía, Gestión Documental, Marketing y Mensajería",
        insurance_shipping_energy: "Seguros, Transporte, Energía y Viajes",
        energy_telecos: "Energía y Telecos",
        medical_material_energy:
            "Material Médico, Energía, Limpieza y Facilities",
        energy_telecos_medical_gases:
            "Energía, Telecos, Gases medicinales, Residuos y Facilities",
        shipping_fleets_cleaning:
            "Transporte, Flotas, Limpieza, Residuos y Embalajes",
        waste_document_management: "Residuos y gestión documental",
        waste: "Residuos",
        document_messenger: "Gestión Documental y Mensajería",
        telecos_transportation: "Telecos, Transporte y Gestión Documental",
        shipping_energy: "Transporte y Energía",
        energy_document_management:
            "Energía, Gestión Documental, Seguros y Telecos",
        home_metatitle:
            "Organización y gestión para el mejor control de gastos para tu empresa.",
        home_metadescription:
            "Mejora el beneficio de tu proyecto o negocio, invirtiendo en la optimización de costes, la productividad y el control de los gastos. A través de nuestra consultaría estratégica, prepararemos un plan de empresa adaptado a tus necesidades, para que juntos consigamos tus objetivos de rentabilidad, mejora continua, eficiencia, reducción de costes y optimización.",
        services_metatitle:
            "La mayor gama de servicios para tus proyectos empresariales",
        services_metadescription:
            "Tenemos una amplia gama de servicios para ti: efactura, revisión de facturas, facturas electrónicas, certificados energéticos, phone manager enfocado a los recursos humanos y el liderazgo, mejorando el capital humano de tu proyecto o negocio. Además, travel solutions para los bussiness traveler y el outsourcing y los trámites y servicios administrativos. Por último, somos auditoría de medioambiente, enfocados en desarrollo sostenible, gestión de residuos… Para enfocar tu empresa a una economía circular, con actividades ecofriendly y siendo menos dañina para el medio ambiente.",
        transport_metatitle:
            "La plataforma para la gestión del trasporte soñada está aquí",
        transport_metadescription:
            "La comunicación interna como nunca la habías visto: tecnología web fácil, escalable, flexible y adaptable a tus necesidades. Herramienta organizativa para tu negocio, viendo toda tu información integrada y personalizada, con el objetivo de rentabilizar el tiempo, y tener un control de gastos detallado, fácil, accesible y rápido. O.K Transport es la revolución en herramientas de control y gestión internos!",
        customers_metatitle:
            "Nuestros clientes son nuestra garantía, ¡Conócelos!",
        customers_metadescription:
            "Durante nuestros 22 años como desarrolladores de proyectos para negocios como el tuyo, nos hemos rodeado de clientes de todos los niveles y capacidades, demostrando que somos una empresa comprometida que busca participar en cualquier proyecto, independientemente de las dimensiones de éste. Entra y mira a nuestros valedores reales, nuestros clientes.",
        about_metatitle: "Llevamos a cabo tus proyectos, contigo, desde 1996",
        about_metadescription:
            "Optima se crea para impulsar y acercar el éxito a proyectos como el tuyo en el año 1996, siendo una empresa Internacional y cercana, donde la implicación con el cliente de nuestros más de 40 colaboradores es máxima. Contribuimos al incremento de tus resultados/beneficios a través de la optimización de costes, además de dar soluciones innovadoras en el ámbito de la gestión interna de tu negocio.",
        contact_metatitle: "Óptima está buscando trabajadores, contáctanos",
        contact_metadescription:
            "En el equipo de Óptima buscamos gente interesada con ganas de trabajar, si tienes alguna duda o quieres trabajar con nosotros no dudes en contactarnos",
        i_have_read_policy:
            "He leido y acepto los Términos y Condiciones de uso y la Política de Privacidad",
        indicate_terms:
            "Por favor indica que esta de acuerdo con Términos y Condiciones de uso y la Política de Privacidad",
        more_services: "Más servicios",
        environmental_consultant: "Consultora Medioambiental",
        best_solutions_eco:
            "Buscamos las mejores soluciones a la gestión de residuos, convirtiendo tu empresa en eco-friendly y ayudándote en el ahorro de costes energéticos.",
        best_optimizations_eco:
            "Optimización de los recursos y ayuda a cumplir toda la normativa legal vigente y las previstas futuras.",
        total_visions_eco:
            "Visión total cada mes de las variables necesarias en la gestión de la RSC (consumos, emisiones, CO2, huella de carbono…).",
        know_benchmark: "Conocemos el benchmark de mercado",
        requires_compromise: "Requerimos tu compromiso, no tu tiempo",
        quality_costs: "Calidad y precio son compatibles",
        some_customers: "Algunos de nuestros clientes",
        features_text: "Externalización en áreas de especialización de Optima.",
        features_title1: "Alcance",
        features_title2: "Modo",
        features_title3: "Principales procesos",
        features1: "Elaboración de RFI / RFQs",
        features2: "Valoración y simulación de escenarios",
        features3: "Preparación en la negociación",
        features4: "Elaboración de cuadros de mando",
        features5: "Servicios de control y mantenimiento",
        average_savings: "Ahorro medio",
        cost_transform_title: "Transformamos los costes en beneficios",
        cost_transform_description:
            "Proporcionamos soluciones diferenciales e innovadoras, que aportan visibilidad, control y valor a su gestión.",
        cost_optimization_text1:
            "Servicio focalizado en la optimización de los gastos indirectos.",
        cost_optimization_text2:
            "Nos convertimos en beneficio para nuestros clientes.",
        ok_transport_text1:
            "Tecnología web fácil, robusta, escalable, flexible y asequible a cualquier tamaño de empresa.",
        ok_transport_text2:
            "Gestiona y controla el transporte desde cualquier lugar.",
        more_services_text1: "Revisión de facturas",
        more_services_text2: "Gestiona Energética",
        more_services_text3: "Phone Manager",
        more_services_text4: "Travel Manager",
        more_services_text5: "Gestión de Compras",
        more_services_text6: "Consultoría Medioambiental",
        know_more: "Si necesitas saber algo más...",
        know_more_text1: "Escríbenos o llamanos:",
        know_more_text2: "Nuestras sedes",
        more_than_a_feeling: "Más de 25 años aumentando beneficios",
        more_than_a_feeling1: "+200 clientes han confiado en nosotros",
        directive_team: "Equipo directivo",
        security_reprography: "Seguridad y Reprografía",
        metso_description: "Reprografía, Courier, Vending, Seguridad y Transporte",
        uni_description: "Courier, Material de Aseo y Material de Oficina",
        audio_description: "Transpote, Impresos y Uniformes",
        deloitte_description: "Material de Oficina y Reprografia",
        adidas_description: "Bolsas",
        indra_description: "Transporte personal",
        coca_description: "Telecomunicaciones y Transporte personal",
        cookies_policy1: "En este sitio solo usamos cookies operativas que se utilizan para prestar nuestros servicios y por este motivo no pueden ser desabilitadas.",
        cookies_policy2: "Utilizamos estas cookies para entender cómo usan los clientes nuestros servicios para implementar mejoras."
    },
    en: {
        cost_optimization: "Cost optimization",
        services: "Services",
        invoice_review: "Invoice review",
        energy_management: "Energy management",
        purchase_management: "Purchase management",
        environmental_consulting: "Environmental consulting",
        clients: "Clients",
        know_us: "Know us",
        know_us2: "Our trajectory",
        contact: "Contact",
        reduce_your_costs: "Reduce your costs,",
        increase_your_benefits: "increase your benefits",
        our_services: "Our services",
        optimization: "Optimization",
        optimization_description: "Optimization of indirect costs",
        benefit: "Beneficio",
        benefits_description: "We become a benefit for our customers",
        success_fee_description: "Your benefit is ours benefit",
        best_solutions:
            "We design the best solutions customized to each client",
        methodology_and_tools: "Methodology and analysis tools",
        savings: "Savings maintained over time",
        adopted_solutions:
            "We join in the implementation of the adopted solutions",
        management_and_control:
            "Management and control during the project. Checking invoices, calculating savings and finding additional improvements",
        market_benchmark: "Our experience provides the market benchmark",
        requirements: "We require your commitment, not your time",
        quality_and_price: "Quality and price are compatible",
        global_saves_title: "Global saving average: 21%",
        transports: "Transports",
        residuals: "Residuals",
        insurances: "Insurance",
        energy: "Energy",
        messenger: "Messenger service",
        travel_expenses: "Travel expenses",
        packaging: "Packaging",
        office_supplies: "Office supplies",
        maintenance: "Maintenance",
        clothing: "Clothing",
        graphic_material: "Graphic material",
        security: "Security",
        cleaning: "Cleaning",
        where_we_are: "Where we are",
        privacy_policy: "Privacy policy",
        terms_of_use: "Terms of use",
        information: "Information",
        web_tech: "Web technology for cost management and control",
        transport_subtitle: "Optima OK Transport transforms data into benefits",
        data: "Data",
        info: "Information",
        knowledge: "Knowledge",
        actions: "Actions",
        benefits: "Benefits",
        what_happened: "Reporting and analysis systems",
        what_happening: "Dashboards with graphic representation",
        where_happening:
            "Geographic information systems, positioned on the map (where it is happening)",
        will_happening: "Command charts",
        will_happen: "Homogenization and evaluation of offers",
        includes: "O.K. Transport includes:",
        integration: "Integration",
        integration_description:
            "Several data sources are integrated, presenting them in a grouped way, facilitating their analysis",
        visibility: "Visibility",
        visibility_description:
            "Have perspective and historical information to be able to set strategies in the medium and long term",
        management_control: "Management control",
        management_control_description1:
            "100% Checking and validation of invoices",
        management_control_description2: "Detection and claim of errors",
        brings: "O.K. Transport brings:",
        customizable_report: "Customizable report",
        customizable_report_description:
            "Apart from the fundamental Reporting, the customization of reports is enabled",
        cost_control: "Cost control",
        cost_control_description:
            "By improving conditions, detecting errors, reducing administrative costs...",
        your_time_profitable: "Make your time profitable",
        your_time_profitable_description1:
            "Studies show that 30% of the time is devoted to reporting",
        your_time_profitable_description2:
            "Perspective and simplification for decision making",
        accomplishments: "Accomplishments SLA 's",
        accomplishments_description:
            "Establish a control and follow-up on the service SLAs agreed with the suppliers",
        control_kpi: "Fixation and control of KPIs",
        control_kpi_description:
            "Let O.K. worry to warn you of any incident or problem, according to the parameters and criteria established in the setup",
        pattern_detection: "Pattern detection",
        pattern_detection_description:
            "Allowing adequate hiring, routes, warehouses ... to real uses",
        we_are_more_than_40: "We are more than 35",
        campaign_workers: "Campaign workers",
        since_1996: "We have been developing projects since 1996",
        meet_us_description1:
            "Optima has collaborated with more than 200 clients in these 25 years.",
        meet_us_description2:
            "More than 500 projects have been analyzed, in more than 20 cost categories.",
        meet_us_description3:
            "More than € 600 M of cost have been analyzed with average savings of 21%.",
        meet_us_description4:
            "Giving coverage throughout Spain, as well as in South America and Europe, through collaborators and delegations.",
        need_more: "Start saving money ",
        something_else: "right now",
        write_or_call: "You can contact us: ",
        contact_to: "Contact",
        your_name: "Your name",
        your_company: "Company",
        your_email: "Your email",
        your_message: "Your message",
        send: "Send",
        our_offices: "Our offices",
        error:
            "We had a problem sending your message. Please send us an email to emira@optimacc.com",
        success:
            "Your message has been sent successfully. Thank you for your feedback.",
        in_our_projects: "In our projects",
        we_provide_solutions: "We provide innovative solutions",
        checking: "Checking",
        innovative_tech:
            "Billing errors can represent between 3% and 5% of the cost.",
        errors_location:
            "Location of errors due to incorrect application of conditions in any cost category.",
        audit_recovery: "Audit Recovery (Checking previous invoices)",
        checking_invoices: "Checking invoices (monthly check)",
        these_are_our: "These are some of",
        our_customers: "our customers",
        only_you: "You are the one missing",
        energy_management_formula:
            "New energy management formula, thanks to the monitoring and control of energy consumption in the cloud.",
        billing_optimization: "Optimization",
        billing_optimization_description:
            "Analyzing historical consumption, we adapt the contracting in the market to the real uses. We achieve savings of up to 15% on the energy cost.",
        energy_management_outsourcing: "Outsourcing",
        energy_management_outsourcing_description:
            "We systematically and continuously manage the energy consumption of the facilities.",
        online_manager: "Online manager",
        online_manager_description:
            "The monitoring and control of your consumption in a single click in a web environment through a cloud platform.",
        energy_efficiency: "Energy efficiency",
        energy_efficiency_description:
            "Reduction of consumption, through the search for the best solutions applying the latest technologies, adapted to the client.",
        tasks_of_management:
            "The tasks of management, control, resolution of incidents ... fall on several departments.",
        ungrateful_job: "Outsourcing an ungrateful and laborious task.",
        laborious_service:
            "You can hire all telecommunications or partially (Landline, Mobile, Data, Smart Network).",
        contact_all:
            "You can contract all or partially telecommunications (Fixed, Mobile, Data, Smart Network).",
        company_size:
            "The responsibility is distributed in various departments, not having the adequate visibility of the cost, for its proper management.",
        service:
            "Service: simplicity and efficiency are the principles on which we base our service",
        know_how:
            "Know how: Expert team and close to the client, able to adapt to their needs",
        travel_policy:
            "Travel Policy: we adapt and implement the optimal solutions for each organization",
        outsourcing_services:
            "Outsourcing of services in areas of Optima specialization. With different alternatives:",
        full_time: "Full, Part / One Time",
        implant_outplant: "Inplant / Outplant",
        bpo_service:
            "Through the BPO service (Business Process Outsourcing) we support the purchase and financial management teams in some of the main business processes. We highlight the following, although they could be extended depending on the needs:",
        rfi_rfq: "Development of RFI / RFQs",
        simulation_scenes: "Assessment and simulation of scenes",
        preparation_negotiation:
            "Preparation in the negotiation (Change suppliers, processes ...).",
        preparation_negotiation_description:
            "(Change suppliers, processes ...).",
        development_scorecards: "Development of scorecards",
        control_and_maintenance: "Control and maintenance services",
        enviromental_management:
            "Environmental management has a strategic focus.",
        enviromental_matters:
            "We advise, process and generate profitability in environmental matters.",
        rsc_policy:
            "Framed within the RSC policy of companies (renewable, circular economy, environment, carbon footprint ...).",
        adaptation_waste:
            "Including the analysis and the adaptation of waste, energy, fleets ... to the policies set by the RSC.",
        solutions_waste:
            "We seek the best solutions for waste management, making your company eco-friendly and helping you save energy costs.",
        optimization_resources:
            "Optimization of resources and help to comply with all current legal regulations and future ones..",
        total_vision:
            "Total vision every month of the necessary variables in the management of the RSC (consumption, emissions, CO2, carbon footprint ...).",
        service_to_develop: "ServiceSection to be developed in 2 phases",
        analysis_and_auditing: "Analysis and auditing of the situation",
        analysis_and_auditing_description:
            "With proposal of changes and new procedures.",
        management_tool: "Cost management tool",
        management_tool_description: "The above is adequate and complete.",
        methodology: "Methodology",
        shipping: "Shipping",
        marketing_messenger: "Marketing and Messenger service",
        energy_fleets_insurance_telecos:
            "Energy, Fleets, Insurance and Telecos",
        waste_travel_energy: "Waste, Travel, Energy and Messenger service",
        energy_document_management_marketing:
            "Energy, Document Management, Marketing and Messenger service",
        insurance_shipping_energy: "Insurance, Shipping, Energy and Travel",
        energy_telecos: "Energy and Telecos",
        medical_material_energy:
            "Medical Material, Energy, Cleaning and Facilities",
        energy_telecos_medical_gases:
            "Energy, Telecos, Medical gases, Residues and Facilities",
        shipping_fleets_cleaning:
            "Shipping, Fleets, Cleaning, Residues and Packaging",
        waste_document_management: "Waste and document management",
        waste: "Waste",
        document_messenger: "Document Management and Messenger service",
        telecos_transportation: "Telecos, Shipping and Document Management",
        shipping_energy: "Shipping and Energy",
        energy_document_management:
            "Energy, Document Management, Insurance and Telecommunications",
        home_metatitle:
            "Organization and management for the best control of expenses for your company.",
        home_metadescription:
            "Improve the benefit of your project or business, investing in cost optimization, productivity and cost control. Through our strategic consulting, we will prepare a business plan adapted to your needs, so that together we can achieve your goals of profitability, continuous improvement, efficiency, cost reduction and optimization.",
        services_metatitle:
            "The widest range of services for your business projects",
        services_metadescription:
            "We have a wide range of services for you: billing, bill review, electronic invoices, energy certificates, phone manager focused on human resources and leadership, improving the human capital of your project or business. In addition, travel solutions for bussiness traveler and outsourcing and administrative procedures and services. Finally, we are environmental auditing, focused on sustainable development, waste management ... To focus your company to a circular economy, with ecofriendly activities and being less harmful to the environment.",
        transport_metatitle:
            "The platform for the management of the dreamed transport is here",
        transport_metadescription:
            "Internal communication as you have never seen it: easy, scalable, flexible web technology adaptable to your needs. Organizational tool for your business, seeing all your information integrated and personalized, in order to make the most of time, and have a detailed, easy, accessible and fast control of expenses. O.K Transport is the revolution in internal control and management tools!",
        customers_metatitle: "Our customers are our guarantee, know them!",
        customers_metadescription:
            "During our 22 years as project developers for businesses like yours, we have surrounded ourselves with clients of all levels and abilities, demonstrating that we are a committed company that seeks to participate in any project, regardless of the dimensions of it. Go in and look at our real supporters, our customers.",
        about_metatitle: "We carry out your projects, with you, since 1996",
        about_metadescription:
            "Optima is created to promote and bring success to projects like yours in 1996, being an international and close company, where the involvement with the client of our more than 40 employees is maximum. We contribute to the increase of your results / benefits through the optimization of costs, besides giving innovative solutions in the field of the internal management of your business.",
        contact_metatitle: "Optima is looking for workers, contact us",
        contact_metadescription:
            "In the Optima team we are looking for interested people who want to work, if you have any questions or want to work with us, do not hesitate to contact us",
        i_have_read_policy:
            "I have read and agree to the Terms and Conditions and Privacy Policy",
        indicate_terms:
            "Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy",
        more_services: "More services",
        environmental_consultant: "Environmental Consultant",
        best_solutions_eco:
            "We look for the best solutions to waste management, turning your company into eco-friendly and helping you save energy costs.",
        best_optimizations_eco:
            "Optimization of resources and helps to comply with all current legal regulations and future ones.",
        total_visions_eco:
            "Total vision each month of the necessary variables in the management of CSR (consumption, emissions, CO2, carbon footprint ...).",
        know_benchmark: "We know the market benchmark",
        requires_compromise: "We require your commitment, not your time",
        quality_costs: "Quality and price are supported",
        some_customers: "Some of our customers",
        features_text: "Externalización en áreas de especialización de Optima.",
        features_title1: "Alcance",
        features_title2: "Modo",
        features_title3: "Main processes",
        features1: "Preparation of RFI / RFQs",
        features2: "Assessment and simulation of scenarios",
        features3: "Negotiation Preparation",
        features4: "Development of dashboards",
        features5: "Control and maintenance services",
        average_savings: "Average savings",
        cost_transform_title: "We transform costs into benefits",
        cost_transform_description:
            "We provide differential and innovative solutions that provide visibility, control and value to your management.",
        cost_optimization_text1:
            "Service focused on the optimization of indirect expenses.",
        cost_optimization_text2: "We become a benefit for our customers.",
        ok_transport_text1:
            "Easy, robust, scalable, flexible and affordable web technology for any business size.",
        ok_transport_text2: "Manage and control transport from anywhere.",
        more_services_text1: "Invoice review",
        more_services_text2: "Energy management",
        more_services_text3: "Phone Manager",
        more_services_text4: "Travel Manager",
        more_services_text5: "Purchasing Management",
        more_services_text6: "Environmental Consulting",
        know_more: "If you need to know something else",
        know_more_text1: "Write us or call us:",
        know_more_text2: "Our headquarters",
        more_than_a_feeling: "More than 25 years increasing profits",
        more_than_a_feeling1: "+200 customers have trusted us",
        directive_team: "Management team",
        security_reprography: "Security and Reprography",
        metso_description: "Reprography, Courier, Vending, Security and Transportation",
        uni_description: "Courier, Toilet Material and Office Supplies",
        audio_description: "Transpote, Printed and Uniforms",
        deloitte_description: "Office Supplies and Reprography",
        adidas_description: "Bags",
        indra_description: "Personal transport",
        coca_description: "Personal transport and telecommunications",
        cookies_policy1: "On this site we only use operational cookies that are used to provide our services and for this reason they cannot be disabled.",
        cookies_policy2: "We use these cookies to understand how customers use our services to implement improvements."
    }
};

const DEFAULT_LANGUAGE = "es";

const lang =
    getPathArray().length > 1 && strings[getPathArray()[1]]
        ? getPathArray()[1]
        : undefined;

export const currentLanguage = lang
    ? lang
    : getCookie("lang")
        ? getCookie("lang")
        : window.navigator.userLanguage
            ? window.navigator.userLanguage.substr(0, 2)
            : window.navigator.language
                ? window.navigator.language.substr(0, 2)
                : DEFAULT_LANGUAGE;

document.getElementsByTagName("html")[0].setAttribute("lang", currentLanguage);

export const addLangToPath = (url, newLang = currentLanguage) => {
    if (url.match(/\/es\/|\/en\//gi)) {
        return url.replace(/\/es\/|\/en\//gi, `/${newLang}/`);
    } else {
        return `/${newLang}${url}`;
    }
};

export const changeLanguage = newLang => {
    createCookie("lang", newLang);
    window.location = addLangToPath(window.location.pathname, newLang);
};

export default function getString(key) {
    const rsrcs = strings[currentLanguage];
    return rsrcs ? rsrcs[key] : strings[DEFAULT_LANGUAGE][key];
}

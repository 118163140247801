import React from "react";
import "./First.css";
import {getString} from "assets"


class FirstContact extends React.Component {

    static checkPolicy() {
        if (!document.getElementById('agree').checked) {
            alert(getString("indicate_terms"));
            return false;
        }
        return true;
    }

    render() {
        return (
            <div className="title-contact-style">
                <div className="container external">
                    <div className="row align-items-center">
                        <div className="col" style={{backgroundColor:"#18255a"}}>
                            <div className="title-h1-contact-style">
                                <h1>{getString("need_more")}<br/>{getString("something_else").toLowerCase()}</h1>
                            </div>
                            <div className="first-description-style">
                                <p>{getString("write_or_call")} <a href='tel:+34 917 990 385'>+34 917 990 385</a></p>
                            </div>
                        </div>
                        <div className="col align-items-center">
                            <div className="form-container"
                                 style={{position: "relative", top: 250}}>
                                <p className="form-text-style ">{getString("contact_to")}</p>
                                <form method="POST"
                                      action="https://us-central1-quo-services.cloudfunctions.net/optima_email"
                                      className="who-style"
                                      onSubmit={FirstContact.checkPolicy}>
                                    <input hidden={true} type="text" name="redirect[success]"
                                           defaultValue={document.URL}/>
                                    <input hidden={true} type="text" name="redirect[error]"
                                           defaultValue={document.URL}/>
                                    <input required type="text" name="email[name]"
                                           placeholder={getString("your_name")}/>
                                    <input required type="text" name="email[company]"
                                           placeholder={getString("your_company")}/>
                                    <input required type="email" name="email[from]"
                                           placeholder={getString("your_email")}/>
                                    <textarea required rows="6" name="email[message]"
                                              placeholder={getString("your_message")}/>
                                    <input type="checkbox" name="checkbox" value="check" id="agree"/>
                                    <a href="/tos/privacy-policy.html"
                                       style={{color: 'rgba(68, 78, 121, 1)'}}>  {getString("i_have_read_policy")}</a><br/>
                                    <input className="btn-send" type="submit" defaultValue={getString("send")}/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FirstContact;
